import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';
import {Contrato, ContratoMaster} from '../schemas/contrato';

@Injectable()
export class ContratoService {

    constructor(private http: Http) {

    }

    gridRastreamento() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/grid/rastreamento/instalando', header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    gridCFTV() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/grid/cftv/instalando', header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    gridMonitoramento() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/grid/monitoramento/instalando', header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    gridManutencao() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/grid/manutencao/instalando', header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    gridInternet() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/grid/internet/instalando', header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    gridVideoMonitoramento() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/grid/video-monitoramento/instalando', header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    getEndereco(id_contrato) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/contrato/get-endereco/' + id_contrato, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    }
                );
        });
    }

    getVeiculo(id_contrato) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/contrato/get-veiculo/' + id_contrato, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    }
                );
        });
    }

    byCliente(id_cliente: number) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/contratos/by-cliente/' + id_cliente, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    masters(): Promise<Array<ContratoMaster>> {
        return new Promise((resolve, fail) => {
            this.http.get(AppSettings.API_ENDPOINT + '/contratos/masters', new MyHeaders().json())
                .subscribe(
                    (data) => resolve(data.json()),
                    (err) => fail(err));
        });
    }

    negar(id_contratos_servicos: number, motivo: string) {
        return new Promise((resolve, fail) => {
            let param = {id_contratos_servicos: id_contratos_servicos, motivo: motivo};
            this.http.put(AppSettings.API_ENDPOINT + '/contrato/negar', param, new MyHeaders().json())
                .subscribe(
                    () => resolve(),
                    () => fail()
                );
        });
    }

    aprovar(json) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.put(AppSettings.API_ENDPOINT + '/contrato/aprovar', json, header.json())
                .subscribe(
                    () => {
                        resolve();
                    },
                    () => {
                        fail();
                    });
        });
    }

    byPK(id_contrato: number) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/contrato/by-pk/' + id_contrato, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    byContrato(contrato: string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/contrato/by-contrato/' + contrato, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    excluir(id_contrato: number) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.delete(AppSettings.API_ENDPOINT + '/contrato/by-pk/' + id_contrato, header.json())
                .subscribe(
                    () => {
                        resolve();
                    },
                    () => {
                        fail();
                    });
        });
    }

    save(json: Contrato) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            if (json.id_contratos_servicos) {

                this.http.put(AppSettings.API_ENDPOINT + '/contrato/by-pk/' + json.id_contratos_servicos, json, header.json())
                    .subscribe(
                        (data) => {
                            resolve(data.json());
                        },
                        (err) => {
                            fail(err);
                        });

            } else {

                this.http.post(AppSettings.API_ENDPOINT + '/contrato/by-pk/0', json, header.json())
                    .subscribe(
                        (data) => {
                            resolve(data.json());
                        },
                        (err) => {
                            fail(err);
                        });

            }
        });
    }

    transferir(json: any) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.post(AppSettings.API_ENDPOINT + '/contrato/transferir', json, header.json())
                .subscribe(
                    (data) => resolve(data.json()),
                    () => fail());
        });
    }

    list2Aprovar(tipo) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + '/contrato/2aprovar/' + tipo, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

}
