import { ProdutosRetirarComponent } from './produtos';

export * from './breadcrumb';
export * from './header/header';
export * from './voltar';

export * from './contratos/telefones/index';
export * from './contratos/emails/index';
export * from './contratos/servicos/index';
export * from './contratos/atendimentos/index';
export * from './contratos/historico-financeiro/index';
export * from './contratos/vendas/index';
export * from './contratos/servicos-adicionais/index';
export * from './contratos/dados-financeiros/index';
export * from './contratos/dados-proprietario/index';
export * from './street/index';
export * from './negar-venda/negar-venda.component';

export * from './loading/index';
export * from './dhtmlxgrid';
export * from './toast';

export * from './orcamentos/cliente-temporario/index';
export * from './orcamentos/financeiro-orcamento/index';
export * from './orcamentos/basico-cliente/index';

export * from './produtos/index';
export * from './search-produto/index';
export * from './searchlist-clientes/index';
export * from './orcamento2financeiro';
export * from './search-clientes/index';
export * from './veiculo/dados-veiculo/index';
export * from './contratos/setores/index';

import {ClienteTemporarioComponent} from './orcamentos/cliente-temporario';
import {BasicoClienteComponent} from './orcamentos/basico-cliente';
import {FinanceiroOrcamentoComponent} from './orcamentos/financeiro-orcamento';
import {TelefonesComponent} from './contratos/telefones';
import {SetoresComponent} from './contratos/setores';
import {EmailsComponent} from './contratos/emails';
import {ServicosComponent} from './contratos/servicos';
import {AtendimentosComponent} from './contratos/atendimentos';
import {HistoricoFinanceiroComponent} from './contratos/historico-financeiro';
import {VendasComponent} from './contratos/vendas';
import {ServicosAdicionaisComponent} from './contratos/servicos-adicionais';
import {DadosFinanceirosComponent} from './contratos/dados-financeiros';
import {DadosVeiculoComponent} from './veiculo/dados-veiculo';
import {StreetComponent} from './street';
import {ProdutosComponent} from './produtos';
import {NegarVendaComponent} from './negar-venda/negar-venda.component';
import {DadosProprietarioComponent} from './contratos/dados-proprietario';

export let ORCAMENTOS_DIRECTIVES = [
    ClienteTemporarioComponent,
    BasicoClienteComponent,
    FinanceiroOrcamentoComponent,
    StreetComponent,
    NegarVendaComponent,
    ProdutosComponent
];

export let CONTRATOS_DIRECTIVES = [
    TelefonesComponent,
    EmailsComponent,
    ServicosComponent,
    AtendimentosComponent,
    HistoricoFinanceiroComponent,
    VendasComponent,
    ServicosAdicionaisComponent,
    DadosFinanceirosComponent,
    DadosVeiculoComponent,
    StreetComponent,
    DadosProprietarioComponent,
    ProdutosComponent,
    ProdutosRetirarComponent,
    SetoresComponent
];

export let CLIENTE_DIRECTIVES = [
    TelefonesComponent,
    EmailsComponent,
    ServicosComponent,
    BasicoClienteComponent,
    HistoricoFinanceiroComponent,
    StreetComponent,
    VendasComponent,
    AtendimentosComponent
];
