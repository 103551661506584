import {Me} from '../schemas/me';

export class Auth{

    static me(): Me{
        let me = window.localStorage.getItem('me');
        //console.log(JSON.stringify(me));
        return JSON.parse(me);
    }

}
