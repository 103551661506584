import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DadosProprietario} from '../../../schemas/index';

@Component({
    selector: 'dados-proprietario',
    templateUrl: 'dados-proprietario.component.html',
    styleUrls: ['dados-proprietario.component.css']
})
export class DadosProprietarioComponent implements OnInit {

    @Input() readonly: boolean = false;
    @Input() dados: DadosProprietario = new DadosProprietario();
    @Output() dados_modified: EventEmitter<any> = new EventEmitter();
    @Input() title: string = 'Dados do Proprietário';

    constructor() {

    }

    emit(){
        //setTimeout(() => {
            this.dados_modified.emit(this.dados);
        //}, 300);
    }

    ngOnInit() {
        if (! this.dados ){
            this.dados = new DadosProprietario();
        }
    }


}
