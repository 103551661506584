import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Contrato, Parceiro, GrupoContrato, Me} from '../../../schemas/index';
import {Events} from '../../../directives/index';
import {Toast} from '../../../components/toast';
import {ParceiroService, GrupoService} from '../../../services/index';
import {Auth} from '../../../utils/auth';

@Component({
    selector: 'dados-financeiros',
    templateUrl: 'dados-financeiros.component.html',
    styleUrls: ['dados-financeiros.component.css']
})
export class DadosFinanceirosComponent implements OnInit, OnDestroy {

    @Input() contrato: Contrato = new Contrato();
    @Input() readonly = false;

    public parceiros: Array<Parceiro> = [];
    public grupos: Array<GrupoContrato> = [];
    public me: Me;

    ngOnDestroy(): any {
        this.event.unsubscribe('UPDATE::BASIC', this.executeBasic);
        this.event.unsubscribe('UPDATE::TAXA', this.executeTaxa);
    }

    constructor(
        private event: Events,
        private sparc: ParceiroService,
        private sgrupo: GrupoService
    ) {

    }

    executeBasic = () => {
        setTimeout(() => {
            if (!this.contrato.ajuste) {
                this.contrato.ajuste = 0;
            }
            let value = 0;
            this.contrato.servicos_adicionais.forEach((s) => {
                value += s.valor;
            });
            this.contrato.ajuste = Number(this.contrato.ajuste);
            this.contrato.basico_mensal = value + this.contrato.ajuste;
        }, 300);
    }

    executeTaxa = () => {
        setTimeout(() => {

            this.contrato.taxa_instalacao = 0;

            this.contrato.produtos.forEach((p) => {
                // console.log(p);
                this.contrato.taxa_instalacao += Number(p.taxa_instalacao);
            });

        }, 300);
    }

    initCobranca () {
        console.log(this.contrato);
    }

    ngOnInit() {
        this.me = Auth.me();
        this.loadParceiro();

        this.loadGrupos();
        this.event.subscribe('UPDATE::BASIC', this.executeBasic);
        this.event.subscribe('UPDATE::TAXA', this.executeTaxa);
    }

    loadGrupos() {
        this.sgrupo.grupos()
            .then((rows: Array<GrupoContrato>) => {
                this.grupos = rows;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Grupos de Contrato!');
            });
    }


    loadParceiro() {
        this.sparc.parceiros()
            .then((rows: Array<Parceiro>) => {
                this.parceiros = rows;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Parceiros!');
            });
    }

    totalTaxa() {
        return (Number(this.contrato.taxa_instalacao) + Number(this.contrato.mao_de_obra)) - Number(this.contrato.desconto);
    }

}

