import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../../components/toast';
import {Router, ActivatedRoute} from '@angular/router';
import {Cliente, Orcamento, Street, Produto, Contrato} from '../../../schemas/index';
import {ClienteService, OrcamentoService, ContratoService} from '../../../services/index';

@Component({
    selector: 'app-orcamento2financeiro',
    templateUrl: 'orcamento2financeiro.component.html',
    styleUrls: ['orcamento2financeiro.component.css']
})
export class Orcamento2financeiroComponent implements OnInit, OnDestroy {

    public clientes: Array<Cliente> = [];
    public cliente: Cliente;

    public cliente_valido = false;

    public orcamento: Orcamento = new Orcamento();

    public produtos: Array<Produto> = [];
    public contratos: Array<Contrato> = [];
    public contrato: Contrato = null;

    public search: any = {};

    private sub: any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    constructor(
        public scliente: ClienteService,
        public sorc: OrcamentoService,
        public scont: ContratoService,
        private router: Router,
        private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {

            if (params['id_orcamento'] && params['id_orcamento'] != '0') {
                this.sorc.byPK(params['id_orcamento'])
                .then((c: Orcamento) => {
                    if (! c.endereco_instalacao) {
                        c.endereco_instalacao = new Street();
                    }
                    if (! c.produtos_retirar) {
                        c.produtos_retirar = [];
                    }
                    this.orcamento = c;

                    if (this.orcamento.cliente && this.orcamento.cliente.id_cliente) {
                        this.scliente.byPK(this.orcamento.cliente.id_cliente)
                        .then((row: Cliente) => {
                            this.cliente_valido = true;
                            this.clientes = [];
                            this.cliente = row;
                            this.checkCliente();
                        })
                        .catch(() => {
                            Toast.error('Problemas para buscar o Cliente');
                        });
                    }

                    if (this.orcamento.id_contratos_servicos) {
                        this.changeContrato();
                    }

                });
            }

        });
    }

    byNome() {
        if (! this.search.nome || this.search.nome.length < 3) {
            return Toast.error('Digite no mínimo 3 caracteres!');
        }
        this.scliente.searchByNome(this.search.nome)
            .then((row: Array<Cliente>) => {
                this.cliente_valido = false;
                this.cliente = null;
                this.clientes = row;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Clientes');
            });
    }

    getStreet(street: Street) {
        //console.log(street);
        this.orcamento.endereco_instalacao = street;
    }

    checked(c: Cliente) {
        this.cliente_valido = false;
        this.clientes = [];
        this.cliente = c;
    }


    changeContrato() {
        setTimeout(() => {
            const idContrato = Number(this.orcamento.id_contratos_servicos);
            this.contratos.forEach((c) => {
                console.log(c.id_contratos_servicos , idContrato);
                if (c.id_contratos_servicos === idContrato) {
                    this.contrato = c;
                }
            });
        }, 500);
    }

    public getContrato(c: Contrato) {
        let t = c.tipo_contrato + ' Contrato: ' + c.contrato ;
        if (c.cod_cliente) {
            t += ' | Código: ' + c.cod_cliente;
        }
        if (c.placa) {
            t += ' | Placa: ' + c.placa;
        }
        return t;
    }

    byCpfCnpj() {
        if (! this.search.cpfcnpj || ( this.search.cpfcnpj.length != 14 && this.search.cpfcnpj.length != 18 )) {
            return Toast.error('Digite o CPF/CNPJ corretamente!');
        }
        this.scliente.searchByCpfCnpj(this.search.cpfcnpj)
            .then((row: Cliente) => {
                this.cliente_valido = false;
                this.clientes = [];
                this.cliente = row;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Clientes');
            });
    }

    checkCliente() {
        this.cliente_valido = true;
        this.scont.byCliente(this.cliente.id_cliente)
            .then((rows: Array<Contrato>) => {
                this.contratos = rows;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Contratos');
            });
    }

    /*
    changeContrato(c: Contrato){
        if (! c.produtos){
            c.produtos = [];
        }
        this.produtos = c.produtos;
    }*/

    save() {

        this.orcamento.cliente = {};
        this.orcamento.cliente.nome = this.cliente.nome;
        this.orcamento.cliente.id_cliente = this.cliente.id_cliente;
        this.orcamento.id_cliente = this.cliente.id_cliente;

        if (this.orcamento.id_contratos_servicos) {
            this.orcamento.id_contratos_servicos = Number(this.orcamento.id_contratos_servicos);
        }

        this.sorc.toAprovar(this.orcamento)
            .then(() => {
                Toast.success('Orçamento enviado para aprovação com Sucesso!');
                this.router.navigate(['/orcamentos']);
            })
            .catch(() => Toast.error('Problemas para enviar!'));

    }

}
