export class Produto {
    public id_produto: number;
    public nome: string;
    public nome_resumido: string;
    public lucro_real: number;
    public custo_real: number;
    public valor: number;
    public valor_produto: number;
    public valor_original: number;
    public icms: number;
    public comissao_real: number;
    public codigo_produto: string;
    public qtde: number;
    public taxa_instalacao: number;
    public qtde_estoque: number;
    public custo: number;
    public lucro: number;
    public impostos: number;
    public comissao_tecnico: number;
    public comissao_vendedor: number;
    public id_empresa_fiscal: number;
}
