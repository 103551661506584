import {Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';
import {Toast} from '../toast';
import {Street, Cep} from '../../schemas';
import {CepService, ClienteService, ContratoService} from '../../services';

@Component({
    selector: 'street',
    templateUrl: 'street.component.html',
    styleUrls: ['street.component.css']
})
export class StreetComponent implements OnInit, OnChanges {

    @Input() readonly = false;
    @Input() street: Street;
    @Input() cliente_street: Street;
    @Input() id_cliente: number;
    @Input() id_contratos_servicos: number;
    @Output() street_modified: EventEmitter<any> = new EventEmitter();
    @Input() title = 'Endereço de Instalação';

    public bairros = [];

    constructor(
        private scep: CepService,
        private scli: ClienteService,
        private scon: ContratoService) {}

    emit() {
        // setTimeout(() => {
        this.street_modified.emit(this.street);
        // }, 300);
    }

    getBairros (cidade) {
        this
            .scep
            .getBairros(cidade).then((bairros: any) => {
                this.bairros = bairros.bairros;
            })
            .catch((err) => console.error(err));
    }

    upper (dado) {
        return String(dado).toUpperCase();
    }

    getCep(cep) {
        this
            .scep
            .getCep(cep)
            .then((cepReturnde: Cep) => {
                this.street.bairro = (cepReturnde.Bairro) ? this.upper(cepReturnde.Bairro) : null;
                this.street.cidade = this.upper(cepReturnde.Cidade);
                this.getBairros(cepReturnde.Cidade);
                if (cepReturnde.Logradouro && cepReturnde.Endereco) {
                    this.street.endereco = this.upper(cepReturnde.Logradouro + ' ' + cepReturnde.Endereco);
                } else {
                    this.street.endereco = '';
                }
                this.street.estado = this.upper(cepReturnde.UF);
                this.emit();
            })
            .catch((err) => console.error(err));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.street.cidade) {
            this.getBairros(this.street.cidade);
        }
    }

    ngOnInit() {
        if (!this.street) {
            this.street = new Street();
        }
    }

    byContrato() {

        if (this.id_contratos_servicos) {
            this
                .scon
                .getEndereco(this.id_contratos_servicos)
                .then((street: Street) => {
                    this.street = street;
                    this.emit();
                })
                .catch(() => {
                    Toast.error('Problemas para Buscar o Endereço');
                });
        }

    }

    byCliente() {

        if (this.cliente_street) {
            this.street = this.cliente_street;
            return this.emit();
        }

        if (this.id_cliente) {
            this
                .scli
                .getEndereco(this.id_cliente)
                .then((street: Street) => {
                    this.street = street;
                    this.emit();
                })
                .catch(() => {
                    Toast.error('Problemas para Buscar o Endereço');
                });
        }

    }

}
