import {Directive, ElementRef, AfterViewInit} from '@angular/core';

declare var jQuery:any;

@Directive({
    selector: '[ngModel][cep]'
})

export class CepDirective implements AfterViewInit{

    constructor(private elementRef: ElementRef) { }

    ngAfterViewInit() {
        jQuery(this.elementRef.nativeElement).mask("00000-000", {
            placeholder: "_____-___"
        });
        jQuery(this.elementRef.nativeElement).attr('maxlength',9);
    }


}
