import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'emails',
    templateUrl: 'emails.component.html',
    styleUrls: ['emails.component.css']
})
export class EmailsComponent implements OnInit {

    @Input() emails: Array<string> = [];
    @Input() readonly = false;

    public email: string;

    constructor() {

    }

    addEmail() {
        if (this.email && String(this.email).length > 0) {
            this.emails.push(this.email);
            this.email = null;
        }
    }

    delEmail(email: string) {
        this.emails.forEach((e) => {
            if (e === email) {
                this.emails.splice(this.emails.indexOf(e), 1);
            }
        });
    }

    ngOnInit() {

    }

}
