import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../../components/toast';
import {Orcamento, ValoresVenda, Venda} from '../../../schemas/index';
import {Router, ActivatedRoute} from '@angular/router';
import {OrcamentoService, VendaService} from '../../../services/index';

@Component({
    selector: 'app-add-orcamento',
    templateUrl: 'add-orcamento.component.html',
    styleUrls: ['add-orcamento.component.css']
})
export class AddOrcamentoComponent implements OnInit, OnDestroy {

    public orcamento: Orcamento = new Orcamento();
    public venda: Venda = new Venda();

    constructor(
        private route:ActivatedRoute,
        private sorc: OrcamentoService,
        private svenda: VendaService,
        private router: Router) {

    }

    private sub:any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {

            if (params['id_orcamento'] && params['id_orcamento'] != '0') {
                this.loadOrcamento(params['id_orcamento']);
            }

            if (params['id_venda'] && params['id_venda'] != '0') {
                this.svenda.byPK(params['id_venda'])
                    .then((v: Venda) => {
                        this.venda = v;
                        this.loadOrcamento(v.id_orcamento);
                    })
                    .catch(() => {
                        Toast.error('Problemas para buscar a Venda');
                    })
            }

        });
    }

    private loadOrcamento(id_orcamento){
        this.sorc.byPK(id_orcamento)
        .then((c:Orcamento)=> {
            if (! c.valores_venda){
                c.valores_venda = new ValoresVenda();
            }
            if (! c.id_parceiro){
                c.id_parceiro = null;
            }
            this.orcamento = c;
        })
        .catch(() => {
            Toast.error('Problemas para buscar o Orçamento');
        })
    }

    save() {

        this.sorc.save(this.orcamento)
            .then((data: any) => {
                Toast.success("Orçamento Salvo com Sucesso!");
                if (! this.orcamento.id_orcamento){
                    this.router.navigate(['/orcamento', data.id_orcamento]);
                }
            })
            .catch(() => {
                Toast.error("Problemas para salvar!");
            });

    }

    checkSave(){

        if (! this.orcamento.cliente_temporario || ! this.orcamento.cliente_temporario.nome){
            return false;
        }

        if(!this.orcamento.id_empresa_fiscal){
            return false;
        }

        if (!this.orcamento.tipo_venda){
            return false;
        }

        if(!this.orcamento.produtos.length){
            return false;
        }

        if(!this.orcamento.id_forma_pagamento){
            return false;
        }

        if(!this.orcamento.id_vendedor){
            return false;
        }

        return (this.orcamento.vendedor);

    }

}
