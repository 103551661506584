import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../components/toast';
import {LoadingComponent} from '../../components/loading';
import {Contrato, Vendedor, ContratoMaster, Street, DadosProprietario, Cliente, ClienteBasico} from '../../schemas/index';
import {ContratoService, FuncionarioService, RastreadorService, ClienteService} from '../../services/index';
import {Router, ActivatedRoute} from '@angular/router';
import {Events} from '../../directives/index';

@Component({
    selector: 'app-novo-rastreamento2',
    templateUrl: 'novo-rastreamento2.component.html',
    styleUrls: ['novo-rastreamento2.component.css']
})
export class NovoRastreamento2Component implements OnInit, OnDestroy {

    public contrato: Contrato = new Contrato();

    public vendores: Array<Vendedor> = [];
    public masters: Array<ContratoMaster> = [];
    public rastreadores = [];

    public cliente: Cliente = new Cliente();

    constructor(
        private route:ActivatedRoute,
        private scont: ContratoService,
        private srast: RastreadorService,
        private scli: ClienteService,
        private event: Events,
        private sfunc: FuncionarioService,
        private router: Router) {

    }

    private sub:any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {

            this.contrato.id_empresa_fiscal = 2;

            if ( params['id_contrato'] && params['id_contrato'] != '0' ) {

                this.scont.byPK(params['id_contrato'])
                    .then((c:Contrato)=> {
                        if (! c.dados_proprietario){
                            c.dados_proprietario = new DadosProprietario();
                        }
                        c.id_empresa_fiscal = c.id_empresa_fiscal || 2;
                        this.contrato = c;
                        this.event.publish("CHECK_UPDATE", true);
                        this.loadCliente(c.id_cliente);
                    })
                    .catch(() => {
                        Toast.error("Problemas para buscar o Contrato!");
                    });

            }

            if ( params['id_cliente'] && params['id_cliente'] != '0' ) {
                this.loadCliente(params['id_cliente']);
            }

            this.loadRastreadores(params['id_contrato']);

        });

        this.loadVendedores();
        this.loadMasters();

    }

    loadCliente(id_cliente){

        LoadingComponent.show();

        this.scli.byPK(id_cliente)
            .then((cli: Cliente) => {
                this.cliente = cli;
                this.contrato.cliente = new ClienteBasico();
                this.contrato.cliente.id_cliente = cli.id_cliente;
                this.contrato.cliente.nome = cli.nome;
                this.contrato.id_cliente = cli.id_cliente;
                this.contrato.tipo_contrato = 'RASTREAMENTO';
                LoadingComponent.hide();
            })
            .catch(() => {
                Toast.error("Problemas para buscar o Cliente!");
            });

    }

    changeVendedor(){
        setTimeout(() => {
            this.vendores.forEach((f) => {
                if (f.id_funcionario == this.contrato.id_vendedor){
                    this.contrato.vendedor = f;
                }
            });
        }, 300);
    }

    getStreet(street: Street){
        this.contrato.endereco_instalacao = street;
    }

    loadRastreadores(id_contrato){
        this.srast.combo(id_contrato)
        .then((ven: Array<any>) => {
            this.rastreadores = ven;
        })
        .catch(() => {
            Toast.error("Problemas para buscar os Rastreadores!");
        });
    }

    loadMasters(){
        this.scont.masters()
            .then((ven: Array<ContratoMaster>) => {
                this.masters = ven;
            })
            .catch(() => {
                Toast.error("Problemas para buscar os Masters!");
            });
    }

    loadVendedores(){
        this.sfunc.vendedores()
            .then((ven: Array<Vendedor>) => {
                this.vendores = ven;
            })
            .catch(() => {
                Toast.error("Problemas para buscar os vendedores!");
            });
    }

    save () {

        if (this.contrato.id_contratos_servicos_mastar) {
            this.contrato.contrato_pago = false;
        }

        this.scont.save(this.contrato)
            .then((ret: any) => {
                if (this.contrato.id_contratos_servicos) {
                    return Toast.success("Contrato Salvo com Sucesso!");
                } else {
                    this.router.navigate(['/rastreamento', ret.id_contratos_servicos])
                }
            })
            .catch(() => {
                Toast.error("PROBLEMAS para salvar o Contrato!");
            });

    }

    checkEmail () {
        if (this.contrato.boleto_por_email === null) {
            return false;
        }

        if (this.contrato.boleto_por_email) {
            if (this.contrato.emails.length === 0) {
                return false;
            }
        }

        return true;
    }

    checkMaster () {
        console.log(this.contrato.id_contratos_servicos_mastar);
        this.contrato.contrato_pago = !this.contrato.id_contratos_servicos_mastar;
    }

    checkSave () {

        if (! this.contrato.placa) {
            return  false;
        }

        if (!this.cliente.street.cidade) {
            return false;
        }

        if (! this.contrato.id_vendedor) {
            return  false;
        }

        if (
            ! this.contrato.placa ||
            ! this.contrato.dados_veiculo.modelo ||
            ! this.contrato.dados_veiculo.marca ||
            ! this.contrato.dados_veiculo.chassi ||
            ! this.contrato.dados_veiculo.renavam
        ) {
            return  false;
        }

        /*
        if(this.contrato.retensao_iss.valueOf() == true || this.contrato.retensao_iss.valueOf() == 'true' ) {
            if (this.cliente.street.cidade.toLowerCase() == "campos dos goytacazes") {
                if (this.cliente.tipo_cliente == 'f') {
                    return false;
                }
            } else if (this.cliente.street.cidade.toLowerCase() != "campos dos goytacazes") {
                return false;
            }
        }*/

        if (! this.contrato.carencia_mensal ) {
            return  false;
        }

        if (! this.contrato.id_grupo_contrato ) {
            return  false;
        }

        return this.checkEmail();
    }

}
