import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Cliente, Street} from '../../schemas/index';
import {ClienteService, Base64, CepService} from '../../services/index';
import {Toast} from '../../components/toast';

declare let moment;

@Component({
    selector: 'app-add-cliente',
    templateUrl: 'add-cliente.component.html',
    styleUrls: ['add-cliente.component.css']
})
export class AddClienteComponent implements OnInit, OnDestroy {

    public cliente: Cliente = new Cliente();

    street(){
        return JSON.stringify(this.cliente.street);
    }

    constructor(
        private route:ActivatedRoute,
        private scliente: ClienteService,
        private router: Router,
        private scep: CepService) {}

    private sub:any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    save(){

        if ( this.cliente.tipo_cliente == 'f' ) {
            let date = moment(this.cliente.nascimento_br, "DD-MM-YYYY");
            this.cliente.nascimento = date.format('YYYY-MM-DD');
        }

        this.scliente.save(this.cliente)
            .then((data: Cliente) => {
                Toast.success("Cliente salva com sucesso!");
                if(!this.cliente.id_cliente){
                    this.router.navigate(['/cliente', data.id_cliente]);
                }
            })
            .catch(()=> {
                console.log("nao salvo");
                Toast.error("Seu cliente NÃO foi salvo!");
            });
    }

    checkSave(){

        if(! this.cliente.telefones || ! this.cliente.telefones.length){
            return false;
        }

        if( ! this.cliente.street ||
            ! this.cliente.street.cidade ||
            ! this.cliente.street.numero ){
            return false;
        }

        if (! this.cliente.cpf_cnpj || (this.cliente.cpf_cnpj.length != 18 && this.cliente.cpf_cnpj.length != 14 )){
            return false;
        }

        if ( this.cliente.tipo_cliente == 'f' ){

            if (!this.cliente.nascimento_br)
            {
                return false;
            }

            let date = moment(this.cliente.nascimento_br, "DD-MM-YYYY");

            if (! date.isValid() ){
                return false;
            }

        } else if ( this.cliente.tipo_cliente == 'j' ){

            if(! this.cliente.razao_social){
                return false;
            }

        }

        return !!(this.cliente.tipo_cliente);

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {

            if ( params['id_cliente'] && params['id_cliente'] != '0' ) {

                this.scliente.byPK(params['id_cliente'])
                    .then((c:Cliente) => {

                        if (!c.street){
                            c.street = new Street();
                        }

                        if (!c.emails){
                            c.emails = [];
                        }

                        if (!c.telefones){
                            c.telefones = [];
                        }

                        if (c.nascimento){
                            let date = new moment(c.nascimento);
                            c.nascimento_br = date.format('DD-MM-YYYY');
                        }

                        this.cliente = c;
                    });

            }

            if (params['cpfcnpj']){
                this.cliente.cpf_cnpj = Base64.decode(params['cpfcnpj']);
            }

        });
    }

}
