import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class EmpresaFiscalService {

    constructor(private http:Http) {

    }

    byPK(id_empresa_fiscal) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/empresa-fiscal/by-pk/" + id_empresa_fiscal, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }


    all() {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/empresa-fiscal/all", header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

}
