import { Component, OnInit, OnDestroy } from '@angular/core';
import {Toast} from '../../../components/toast';
import {Router, ActivatedRoute} from '@angular/router';
import {Venda, Cliente} from '../../../schemas/index';
import {VendaService, StatusVendaService, ClienteService} from '../../../services/index';

@Component({
    selector: 'app-mudar-status',
    templateUrl: './mudar-status.component.html',
    styleUrls: ['./mudar-status.component.css']
})
export class MudarStatusComponent implements OnInit, OnDestroy {

    constructor(
        private sstatus: StatusVendaService,
        private svenda: VendaService,
        private router: Router,
        private scliente: ClienteService,
        private route:ActivatedRoute
    ) {

    }

    private sub:any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    ngOnInit() {
        this.loadStatus();
        this.sub = this.route.params.subscribe(params => {

            if (params['id_venda'] && params['id_venda'] != '0') {
                this.svenda.byPK(params['id_venda'])
                    .then((c:Venda)=> {
                        this.venda = c;
                        this.loadCliente(c.cliente.id_cliente);
                    })
                    .catch(() => {
                        Toast.error("Problemas para buscar a Venda!");
                    });
            }

        });
    }

    loadCliente(id_cliente){
        this.scliente.byPK(id_cliente)
            .then((c:Cliente)=> {
                this.cliente = c;
            })
            .catch(() => {
                Toast.error("Problemas para buscar o Cliente!");
            });
    }

    public status: Array<any> = [];
    public venda : Venda = new Venda();
    public cliente: Cliente = new Cliente();

    loadStatus(){
        this.sstatus.all()
            .then((rows: Array<any>) => {
                this.status = rows;
            })
            .catch(() => {
                Toast.error("Problemas para buscar o Status!");
            });
    }

    changeStatus (){

        this.svenda.mudarStatus(this.venda.id_venda, this.venda.id_status_venda)
            .then(() => {
                Toast.success("Status Alterado com Sucesso!");
                this.router.navigate(['/start'])
            })
            .catch(() => {
                Toast.error("Problemas para mudar o Status!");
            });

    }

}
