import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../components/toast';
import {Router} from '@angular/router';
import {Orcamento, Contrato} from '../../schemas/index';
import {OrcamentoService, ContratoService} from '../../services/index';
import {Events} from '../../directives/events';

@Component({
    selector: 'app-list-aprovar',
    templateUrl: 'list-aprovar.component.html',
    styleUrls: ['list-aprovar.component.css']
})
export class ListAprovarComponent implements OnInit, OnDestroy {

    ngOnDestroy(): void {
        this.event.unsubscribe('RELOAD-APROVAR-ORCAMENTO',this.reloadOrcamento);
    }


    public orcamentos: Array<Orcamento> = [];
    public cftvs: Array<Contrato> = [];
    public monitoramentos: Array<Contrato> = [];
    public rastreamentos: Array<Contrato> = [];
    public internets: Array<Contrato> = [];
    public videos: Array<Contrato> = [];
    public manutencoes: Array<Contrato> = [];

    constructor(
        private event: Events,
        private sorc: OrcamentoService,
        private scont: ContratoService,
        private router: Router) {

    }

    editOrcamento(id_orcamento){
        this.router.navigate(['/orcamento', id_orcamento]);
    }

    editMonitoramento(id_orcamento){
        this.router.navigate(['/monitoramento', id_orcamento]);
    }

    editManutencao(id_orcamento){
        this.router.navigate(['/manutencao', id_orcamento]);
    }

    editRastreamento(id_orcamento){
        this.router.navigate(['/rastreamento', id_orcamento]);
    }

    editCFTV(id_orcamento){
        this.router.navigate(['/cftv', id_orcamento]);
    }

    editInternet(id_orcamento){
        this.router.navigate(['/internet', id_orcamento]);
    }

    editVideo(id_orcamento){
        this.router.navigate(['/video-monitoramento', id_orcamento]);
    }

    aprovarOrcamento(id_orcamento){
        this.router.navigate(['/aprovar-orcamento', id_orcamento]);
    }

    aprovarContrato(id_contrato){
        this.router.navigate(['/aprovar-contrato', id_contrato]);
    }


    reloadOrcamento = () => {
        this.listOrcamento();
    };

    reloadContrato = () => {
        this.listMonitoramento();
        this.listRastreamento();
        this.listCFTV();
        this.listManutencoes();
        this.listInternets();
        this.listVideos();
    };

    ngOnInit() {

        this.event.subscribe('RELOAD-APROVAR-ORCAMENTO',this.reloadOrcamento);
        this.event.subscribe('RELOAD-APROVAR-CONTRATO',this.reloadContrato);

        this.listOrcamento();
        this.listMonitoramento();
        this.listRastreamento();
        this.listCFTV();
        this.listInternets();
        this.listVideos();
        this.listManutencoes();
    }

    listManutencoes(){
        this.scont.list2Aprovar('MANUTENCAO')
            .then((rows: Array<any>) => {
                rows.sort((a, b) => {
                    if (a.nome > b.nome) {
                        return 1;
                    }
                    if (a.nome < b.nome) {
                        return -1;
                    }
                    return 0;
                });
                this.manutencoes = rows;
            })
            .catch(() => {
                Toast.error("Problemas para buscar os CFTV.");
            });
    }

    listCFTV(){
        this.scont.list2Aprovar('CFTV')
        .then((rows: Array<any>) => {
            rows.sort((a, b) => {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
            this.cftvs = rows;
        })
        .catch(() => {
            Toast.error("Problemas para buscar os CFTV.");
        });
    }

    listRastreamento(){
        this.scont.list2Aprovar('RASTREAMENTO')
        .then((rows: Array<any>) => {
            rows.sort((a, b) => {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
            this.rastreamentos = rows;
        })
        .catch(() => {
            Toast.error("Problemas para buscar os Rastreamentos.");
        });
    }

    listMonitoramento(){
        this.scont.list2Aprovar('MONITORAMENTO')
            .then((rows: Array<any>) => {
                rows.sort((a, b) => {
                    if (a.nome > b.nome) {
                        return 1;
                    }
                    if (a.nome < b.nome) {
                        return -1;
                    }
                    return 0;
                });
                this.monitoramentos = rows;
            })
            .catch(() => {
                Toast.error("Problemas para buscar os Monitoramentos.");
            });
    }

    listInternets(){
        this.scont.list2Aprovar('INTERNET')
        .then((rows: Array<any>) => {
            rows.sort((a, b) => {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
            this.internets = rows;
        })
        .catch(() => {
            Toast.error("Problemas para buscar os Monitoramentos.");
        });
    }

    listVideos(){
        this.scont.list2Aprovar('VIDEO')
        .then((rows: Array<any>) => {
            rows.sort((a, b) => {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
            this.videos = rows;
        })
        .catch(() => {
            Toast.error("Problemas para buscar os Videos Monitoramentos.");
        });
    }

    listOrcamento(){

        this.sorc.list2Aprovar()
            .then((rows: Array<Orcamento>) => {
                rows.sort(function (a, b) {
                    if (a.cliente.nome > b.cliente.nome) {
                        return 1;
                    }
                    if (a.cliente.nome < b.cliente.nome) {
                        return -1;
                    }
                    return 0;
                });
                this.orcamentos = rows;
            })
            .catch(() => {
                Toast.error("Problemas para buscar os Orçamentos.");
            });

    }

}
