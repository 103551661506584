export class Street {
    public cidade: string;
    public bairro: string;
    public estado: string;
    public numero: string;
    public endereco: string;
    public complemento: string;
    public cep: string;
    public telefone1: string;
    public telefone2: string;
}
