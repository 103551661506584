import {Component, OnInit, Input} from '@angular/core';
import {DhtmlXGrid} from '../../../components/dhtmlxgrid';
import {AppSettings} from '../../../app.settings';
import {Toast} from '../../../components/toast';
import {BoletoService} from '../../../services/index';
import {Boleto} from '../../../schemas/index';

declare var jQuery : any;

@Component({
    selector: 'historico-financeiro',
    templateUrl: 'historico-financeiro.component.html',
    styleUrls: ['historico-financeiro.component.css']
})
export class HistoricoFinanceiroComponent implements OnInit {

    constructor(private sboleto: BoletoService) {

    }

    private _id_cliente: number;

    @Input()
    set id_cliente( value ){
        this._id_cliente = value;
    }

    private _id_contrato: number;

    @Input()
    set id_contrato( value ){
        this._id_contrato = value;
    }

    public boleto : Boleto = new Boleto();

    public mygrid: DhtmlXGrid;

    public formas : Array<any> = [
        {value: 'cheque', name: "Cheque"},
        {value: 'dinheiro', name: "Dinheiro"},
        {value: 'banco', name: "Depósito Bancário"},
        {value: 'cartao', name: "Cartão"},
        {value: 'isento', name: "Isento"},
        {value: 'permuta', name: "Permuta"},
        {value: 'cortesia', name: "Cortesia"}
    ];

    ngOnInit() {

        setTimeout(() => {

            this.mygrid = new DhtmlXGrid('gridboxHistorico');
            this.mygrid.setImagePath("/javascript/codebase/imgs/");
            this.mygrid.setHeader("Número,Data,Data. Pag,Tipo Boleto,Valor,Status,Pago,Placa/Cód,Nota");
            this.mygrid.setInitWidths("80,80,80,130,60,180,50,85,110");
            this.mygrid.setColAlign("center,center,center,center,right,center,center,center,center");
            this.mygrid.setColTypes("ro,ro,ro,ro,ro,ro,ro,ro,ro");
            this.mygrid.attachHeader("#text_filter,#text_filter,#text_filter,#select_filter,#numeric_filter,#select_filter,#select_filter,#text_filter,#numeric_filter");
            this.mygrid.setColSorting("int,dateSort,dateSort,str,int,str,str,str,int");
            this.mygrid.enableAutoHeight(true,400);
            this.mygrid.setSkin("dhx_web");

            this.mygrid.attachEvent("onRowDblClicked", () => {
                window.open('http://ca.centeralarm.com.br/blt/boleto.php?id_boleto='+ this.mygrid.getSelectedId(), '_blank',
                    "chrome,centerscreen,dependent=YES,dialog=YES,modal=YES,width=700, height=400,scrollbars=yes")
            });

            this.mygrid.init();


            if (this._id_cliente){
                this.loadGridCliente()
            } else if( this._id_contrato){
                this.loadGridContrato();
            }

        },100);


    }

    loadGridCliente() {
        this.mygrid.clearAll();
        this.mygrid.load(AppSettings.API_ENDPOINT + "/grid/cliente/boletos/" + this._id_cliente, () => {
            this.mygrid.filterByAll();
        }, 'json');
    };

    loadGridContrato() {
        this.mygrid.clearAll();
        this.mygrid.load(AppSettings.API_ENDPOINT + "/grid/contrato/boletos/" + this._id_contrato, () => {
            this.mygrid.filterByAll();
        }, 'json');
    };

    printall(){
        window.open('http://ca.centeralarm.com.br/cliente/printall.php?ids='+ this.mygrid.getAllItemIds(), '_blank',
            "chrome,centerscreen,dependent=YES,dialog=YES,modal=YES,width=700, height=460,scrollbars=yes")
    }

    baixaBoleto(){

        if (this.mygrid.getSelectedId()) {

            this.boleto.id_boletos = this.mygrid.getSelectedId();

            this.sboleto.baixar(this.boleto)
                .then(() => {

                    if (this._id_cliente){
                        this.loadGridCliente()
                    } else if( this._id_contrato){
                        this.loadGridContrato();
                    }

                    this.boleto = new Boleto();
                    Toast.success('Boleto Baixado com Sucesso!');
                    jQuery('#modalBaixarBoleto').closeModal();

                })
                .catch(() => {
                    Toast.error('Problemas para baixar o boleto!');
                });

        }

    }

    sendBoletoEmail(){

    }

    sendBoletoSMS(){

    }

}
