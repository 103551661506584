import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({

    selector: 'orcamento2financeiro',
    template: `<button (click)="gotoFinanceiro()" class="waves-effect yellow black-text waves-light btn">
                    <i class="material-icons right">add_shopping_cart</i>ENVIAR PARA O FINANCEIRO
                </button>`
})
export class Orcamento2Financeiro{

    @Input() id_orcamento : Number;

    constructor(private router: Router){

    }

    gotoFinanceiro(){
        this.router.navigate(['/orcamento2financeiro', this.id_orcamento]);
    }

}
