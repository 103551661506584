export class DadosProprietario{
    public nome: string;
    public email : number;
    public cep: string;
    public endereco: string;
    public numero: string;
    public cidade: string;
    public estado: string;
    public bairro: string;
    public complemento: string;
    public telefone1: string;
    public telefone2: string;
    public cpf_cnpj: string;
    public rg_ie: string;
}
