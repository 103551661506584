import {Component, OnInit} from '@angular/core';
import {Cliente} from '../../schemas/index';
import {Router} from '@angular/router';

@Component({
    selector: 'app-novo-master',
    templateUrl: './novo-master.component.html',
    styleUrls: ['./novo-master.component.css']
})
export class NovoMasterComponent implements OnInit {

    public cliente: Cliente;

    constructor(private router: Router) {

    }

    ngOnInit() {

    }

    getCliente(cli: Cliente){
        this.cliente = cli;
        this.router.navigate(['/novo-master', cli.id_cliente]);
    }

}
