import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthManager, AuthAdmin} from './services';
import {
    AddClienteComponent,
    AprovarContratosComponent,
    BuscarClienteComponent,
    LoginComponent,
    ListAprovarComponent,
    PrincipalComponent,
    RastreamentoComponent,
    MonitoramentoComponent,
    InternetComponent,
    CftvComponent,
    NovoCftvComponent,
    NovoCftv2Component,
    NovoRastreamentoComponent,
    NovoRastreamento2Component,
    NovoMonitoramentoComponent,
    NovoMonitoramento2Component,
    OrcamentosComponent,
    Orcamento2financeiroComponent,
    ListAprovarOrcamentosComponent,
    AprovarOrcamentoComponent,
    ClientesComponent,
    AddOrcamentoComponent,
    MudarStatusComponent,
    NovoMasterComponent,
    NovoMaster2Component,
    NovoInternetComponent,
    NovoInternet2Component,
    NovoVideoComponent,
    VideoMonitoramentoComponent,
    NovoVideo2Component,
    ManutancaoComponent,
    NovoContratroManutencaoComponent,
    NovoManutencao2Component,
    TransferirComponent,
    Transferir2Component
} from './pages/index';

import { PrintOrcamentoComponent } from './pages/print-orcamento/print-orcamento.component';

export const appRoutes: Routes = [
    {path: '', redirectTo: 'start', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'start2', component: PrincipalComponent, canActivate: [AuthManager]},
    {path: 'start', component: ListAprovarComponent, canActivate: [AuthAdmin]},
    {path: 'buscar-cliente', component: BuscarClienteComponent, canActivate: [AuthManager]},
    {path: 'rastreamentos', component: RastreamentoComponent, canActivate: [AuthManager]},
    {path: 'monitoramentos', component: MonitoramentoComponent, canActivate: [AuthManager]},
    {path: 'internets', component: InternetComponent, canActivate: [AuthManager]},
    {path: 'cftvs', component: CftvComponent, canActivate: [AuthManager]},
    {path: 'video-monitoramentos', component: VideoMonitoramentoComponent, canActivate: [AuthManager]},
    {path: 'manutencoes', component: ManutancaoComponent, canActivate: [AuthManager]},

    {path: 'novo-manutencao', component: NovoContratroManutencaoComponent, canActivate: [AuthManager]},
    {path: 'novo-manutencao/:id_cliente', component: NovoManutencao2Component, canActivate: [AuthManager]},
    {path: 'manutencao/:id_contrato', component: NovoManutencao2Component, canActivate: [AuthManager]},

    {path: 'novo-video', component: NovoVideoComponent, canActivate: [AuthManager]},
    {path: 'novo-video/:id_cliente', component: NovoVideo2Component, canActivate: [AuthManager]},
    {path: 'video-monitoramento/:id_contrato', component: NovoVideo2Component, canActivate: [AuthManager]},

    {path: 'novo-internet', component: NovoInternetComponent, canActivate: [AuthManager]},
    {path: 'novo-internet/:id_cliente', component: NovoInternet2Component, canActivate: [AuthManager]},
    {path: 'internet/:id_contrato', component: NovoInternet2Component, canActivate: [AuthManager]},

    {path: 'transferir', component: TransferirComponent, canActivate: [AuthManager]},
    {path: 'transferir/:id_cliente', component: Transferir2Component, canActivate: [AuthManager]},

    {path: 'novo-master', component: NovoMasterComponent, canActivate: [AuthAdmin]},
    {path: 'novo-master/:id_cliente', component: NovoMaster2Component, canActivate: [AuthAdmin]},
    {path: 'master/:id_contrato', component: NovoMaster2Component, canActivate: [AuthAdmin]},

    {path: 'novo-cftv', component: NovoCftvComponent, canActivate: [AuthManager]},
    {path: 'novo-cftv/:id_cliente', component: NovoCftv2Component, canActivate: [AuthManager]},
    {path: 'cftv/:id_contrato', component: NovoCftv2Component, canActivate: [AuthManager]},

    {path: 'novo-rastreamento', component: NovoRastreamentoComponent, canActivate: [AuthManager]},
    {path: 'novo-rastreamento/:id_cliente', component: NovoRastreamento2Component, canActivate: [AuthManager]},
    {path: 'rastreamento/:id_contrato', component: NovoRastreamento2Component, canActivate: [AuthManager]},

    {path: 'novo-monitoramento', component: NovoMonitoramentoComponent, canActivate: [AuthManager]},
    {path: 'novo-monitoramento/:id_cliente', component: NovoMonitoramento2Component, canActivate: [AuthManager]},
    {path: 'monitoramento/:id_contrato', component: NovoMonitoramento2Component, canActivate: [AuthManager]},

    {path: 'status-venda/:id_venda', component: MudarStatusComponent, canActivate: [AuthAdmin]},
    {path: 'clientes', component: ClientesComponent, canActivate: [AuthManager]},
    {path: 'orcamentos', component: OrcamentosComponent, canActivate: [AuthManager]},
    {path: 'orcamento/:id_orcamento', component: AddOrcamentoComponent, canActivate: [AuthManager]},
    {path: 'venda/:id_venda', component: AddOrcamentoComponent, canActivate: [AuthManager]},
    {path: 'orcamento2financeiro/:id_orcamento', component: Orcamento2financeiroComponent, canActivate: [AuthManager]},
    {path: 'orcamento', component: AddOrcamentoComponent, canActivate: [AuthManager]},
    {path: 'print-orcamento/:id_orcamento', component: PrintOrcamentoComponent, canActivate: [AuthManager]},
    {path: 'aprovar-orcamento', component: ListAprovarOrcamentosComponent, canActivate: [AuthAdmin]},
    {path: 'aprovar-orcamento/:id_orcamento', component: AprovarOrcamentoComponent, canActivate: [AuthAdmin]},
    {path: 'aprovar-contrato/:id_contrato', component: AprovarContratosComponent, canActivate: [AuthAdmin]},
    {path: 'cliente/:id_cliente', component: AddClienteComponent, canActivate: [AuthManager]},
    {path: 'cliente/:id_cliente/:cpfcnpj', component: AddClienteComponent, canActivate: [AuthManager]}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
