import {Directive, AfterViewInit, ElementRef } from '@angular/core';


declare var jQuery: any;

@Directive({
    selector: '[telefone]',
    host: {
        '(ngModelChange)': 'onInputChange($event)'
    }
})
export class TelefoneDirective implements AfterViewInit {


    constructor(private elementRef: ElementRef) { }

    public is9: boolean = false;

    ngAfterViewInit() {
        this.set8();
        jQuery(this.elementRef.nativeElement).attr('maxlength',15);
    }

    set9(numero){
        this.setClear();
        this.is9 = true;
        this.elementRef.nativeElement.value = numero;
        // console.log();
        jQuery(this.elementRef.nativeElement).mask("(00) 00000-0000", {
            placeholder: "(__) _____-____"
        });
    }

    set8(){
        this.setClear();
        this.is9 = false;

        jQuery(this.elementRef.nativeElement).mask("(00) 0000-0000", {
            placeholder: "(__) ____-____"
        });
    }

    setClear(){
        jQuery(this.elementRef.nativeElement).unmask();
    }

    onInputChange($event) {

        //console.log($event);

        if ($event.length > 14){
            this.set9($event);
        }

        if ($event.length <= 14 && this.is9){
            this.set8();
        }

    }

}
