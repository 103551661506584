import {Component, OnInit, Input} from '@angular/core';
import {ServicoAdicional} from '../../../schemas/index';
import {ServicosService} from '../../../services/index';
import {Events} from '../../../directives/index';
import {Toast} from '../../../components/toast';

@Component({
    selector: 'servicos-adicionais',
    templateUrl: 'servicos-adicionais.component.html',
    styleUrls: ['servicos-adicionais.component.css']
})
export class ServicosAdicionaisComponent implements OnInit {

    @Input() servicos : Array<ServicoAdicional> = [];
    @Input() tipo_contrato: string;
    @Input() disabled : boolean = false;
    @Input() qtde : boolean = false;

    public array_servicos: Array<ServicoAdicional> = [];

    public id: number;
    public qtde_num: number = 1;

    constructor(private sserv: ServicosService, private event: Events) {

    }

    ngOnInit() {
        this.loadService();
    }

    delService(id){
        this.servicos.forEach((s) => {
            if (s.id_servicos_monitoramento == id){
                this.servicos.splice(this.servicos.indexOf(s),1);
            }
        });
        this.event.publish('UPDATE::BASIC');
    }

    rem(serv: ServicoAdicional){

        serv.add = Number(serv.add) || 1;
        if (!serv.valor_minimo) {
            serv.valor_minimo = serv.valor;
        }
        if (serv.add < serv.valor_minimo) {
            return;
        }
        serv.valor = serv.add;
        this.event.publish('UPDATE::BASIC');
    }

    addServico(){
        if (! this.id){
            Toast.error('Informe Qual serviço devo Incluir!');
            return;
        }
        this.delService(this.id);
        this.array_servicos.forEach((s) => {
            if (s.id_servicos_monitoramento == this.id){
                if (this.qtde) {
                    let v = s.valor;
                    let sa : ServicoAdicional = new ServicoAdicional();
                    sa.valor = v * this.qtde_num;
                    sa.id_servicos_monitoramento = s.id_servicos_monitoramento;
                    sa.nome = s.nome;
                    sa.texto = s.texto;
                    sa.download = s.download;
                    sa.internet_dedicado = s.internet_dedicado;
                    sa.limit_dedicado = s.limit_dedicado;
                    sa.upload = s.upload;
                    sa.qtde = this.qtde_num;
                    sa.valor_minimo = s.valor_minimo;
                    this.servicos.push(sa);
                } else {
                    console.log(s);
                    this.servicos.push(s);
                    this.event.publish('UPDATE::SERVICE', s);
                }

            }
        });
        this.id = null;
        this.event.publish('UPDATE::BASIC');

    }


    loadService(){

        if (this.disabled){
            return;
        }

        this.sserv.combo(this.tipo_contrato)
            .then((rows : Array<ServicoAdicional>) => {
                this.array_servicos = rows;
            })
            .catch(() => {
                Toast.error("Problemas para carregar o serviço");
            });

    }

    getTexto(){
        let texto = null;
        this.array_servicos.forEach((s: ServicoAdicional) => {
            if (this.id ==  s.id_servicos_monitoramento){
                texto = s.texto;
            }
        });
        return (texto) ? texto.replace(new RegExp('\r?\n','g'), '<br />') : null;
    }

    getTitle(){
        let texto = null;
        this.array_servicos.forEach((s: ServicoAdicional) => {
            if (this.id ==  s.id_servicos_monitoramento){
                texto = s.nome;
            }
        });
        return texto;
    }

}
