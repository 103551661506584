import {Component, OnInit, Input} from '@angular/core';
import {Telefone} from '../../../schemas/index';

@Component({
    selector: 'telefones',
    templateUrl: 'telefones.component.html',
    styleUrls: ['telefones.component.css']
})
export class TelefonesComponent implements OnInit {

    @Input() telefones: Array<Telefone> = [];
    @Input() readonly: boolean = false;

    public phone: Telefone = new Telefone();

    constructor() {

    }

    addTelefone() {
        this.telefones.push(this.phone);
        this.phone = new Telefone();
    }

    delTelefone(tel: Telefone) {
        this.telefones.forEach((e) => {
            if (e == tel) {
                this.telefones.splice(this.telefones.indexOf(e), 1);
            }
        });
    }

    ngOnInit() {

    }

}
