export class FormaPagamento{

    public id_forma_pagamento: number;
    public forma_pagamento: string;
    public parcela_minima: number;
    public entrada: number;
    public habilita_entrada: boolean;
    public parcelas: number;
    public id_empresa: number;
    public tipo_financeiro: string;

}
