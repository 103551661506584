import {ClienteTemporario} from './cliente_temporario';
import {Produto} from './produto';
import {FormaPagamento} from './forma_pagamento';
import {ValoresVenda} from './valores_venda';
import {Street} from './street';

export class Orcamento {

    public id_orcamento: number;
    public cliente_temporario: ClienteTemporario = new ClienteTemporario();
    public cliente: any = {};
    public data_orcamento: string;
    public data_validade: string;
    public obs: string;
    public aprovado = false;
    public obs_os: string;
    public produtos: Array<Produto> = [];
    public valor_final_sem_desconto: number;
    public criar_os: number;
    public entrada_recebida: number;
    public tipo_nota: string;
    public valor_final: number;
    public acrescimo_valor: number;
    public forma_pagamento: FormaPagamento = new FormaPagamento();
    public id_forma_pagamento: number;
    public id_empresa_fiscal: number;
    public id_parceiro: number;
    public mao_de_obra: number | string;
    public desconto: number | string;
    public valores_venda: ValoresVenda = new ValoresVenda();
    public valor_produtos: number;
    public vendedor: any = {};
    public id_vendedor: number;
    public tipo_venda: string;
    public endereco_instalacao: Street = new Street();
    public id_contratos_servicos: number;
    public id_cliente: number;
    public produtos_retirar: Array<Produto> = [];

    static sumTaxaInstalacao(orc: Orcamento, updateEntrada: boolean = true) {


        let forma_pagamento = orc.forma_pagamento;

        let valores_venda = new ValoresVenda();
        valores_venda.custo = 0;
        valores_venda.lucro = 0;
        valores_venda.impostos = 0;
        valores_venda.comissao_tecnico = 0;
        valores_venda.comissao_vendedor = 0;
        valores_venda.entrada = orc.valores_venda.entrada;

        let valor_produtos = 0;


        if (!orc.mao_de_obra) {
            orc.mao_de_obra = 0;
        }

        if (!orc.desconto) {
            orc.desconto = 0;
        }

        if (orc.tipo_venda === 'VENDA') {
            orc.produtos.forEach((p) => {

                if (!p.impostos) {
                    p.impostos = 0;
                }

                if (!p.comissao_tecnico) {
                    p.comissao_tecnico = 0;
                }

                if (!p.comissao_vendedor) {
                    p.comissao_vendedor = 0;
                }

                valores_venda.custo += p.custo * p.qtde;
                valores_venda.lucro += p.lucro * p.qtde;
                valores_venda.impostos += p.impostos * p.qtde;
                valores_venda.comissao_tecnico += p.comissao_tecnico * p.qtde;
                valores_venda.comissao_vendedor += p.comissao_vendedor * p.qtde;
                valor_produtos += p.valor * p.qtde;

            });
        }

        orc.valor_produtos = valor_produtos;

        orc.valores_venda = valores_venda;



        orc.valores_venda.num_parcelas = forma_pagamento.parcelas || 0;
        orc.valor_final = orc.valor_produtos + Number(orc.mao_de_obra) - Number(orc.desconto);
        orc.valor_final_sem_desconto = orc.valor_produtos + Number(orc.mao_de_obra);

        // console.log(orc.valores_venda.entrada);

        // let entrada = forma_pagamento.entrada || 0.00;
        // orc.valores_venda.entrada = Number(((entrada) ? ( ( orc.valor_final / 100) * entrada) : 0 ).toFixed(2));

        orc.valores_venda.valor_parcela = (orc.valor_final - Number(orc.valores_venda.entrada)) / forma_pagamento.parcelas || 0.00;

    }
}
