import {Component, OnInit} from '@angular/core';
import {Toast} from '../../components/toast';
import {ClienteService,Base64} from '../../services/index';
import {Cliente,Street} from '../../schemas/index';
import {Router} from '@angular/router';

declare let jQuery : any;

@Component({

    selector: 'app-buscar-cliente',
    templateUrl: 'buscar-cliente.component.html',
    styleUrls: ['buscar-cliente.component.css']
})
export class BuscarClienteComponent implements OnInit {

    public cpfcnpj: any;
    public nome: any;
    public razao_social: any;
    public cliente: Cliente;
    public clientes: Array<Cliente> = [];
    public habilitarCadastrar: boolean = false;
    public dado_invalido: boolean = false;

    consultaCpfCnpj(){
        console.log(this.cpfcnpj);
        this.scliente.checarCpfCnpj(this.cpfcnpj)
            .then(() => {

                this.dado_invalido = false;

                this.scliente.searchByCpfCnpj(this.cpfcnpj)
                    .then((cliente: Cliente) => {
                        if (!cliente.street){
                            cliente.street = new Street();
                        }
                        this.cliente = cliente;
                        this.habilitarCadastrar = false;
                    })
                    .catch(() => {
                        this.habilitarCadastrar = true;
                    });

            })
            .catch(() => {
                this.dado_invalido = true;
            })
    }

    byNome(){
        this.scliente.searchByNome(this.nome)
        .then((dado: Array<Cliente>) => {
            this.clientes = dado;
            this.cliente = null;
            this.habilitarCadastrar = false;
        })
        .catch(() => {
            Toast.error("Não foi possível Buscar pelo nome!");
        });
    }

    byRazaoSocial(){
        this.scliente.searchByRazaoSocial(this.razao_social)
            .then((dado: Array<Cliente>) => {
                this.clientes = dado;
                this.cliente = null;
                this.habilitarCadastrar = false;
            })
            .catch(() => {
                Toast.error("Não foi possível Buscar pela razao social!");
            });
    }

    toCliente(id_cliente){
        this.router.navigate(['/cliente', id_cliente]);
    }

    toNewCliente(){
        this.router.navigate(['/cliente', 0, Base64.encode(this.cpfcnpj)]);
    }

    constructor(public scliente: ClienteService, public router: Router) {

    }

    ngOnInit() {

    }

}
