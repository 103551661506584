import {Component, OnInit, Input} from '@angular/core';
import {DhtmlXGrid} from '../../dhtmlxgrid';
import {AppSettings} from '../../../app.settings';

@Component({
  selector: 'servicos',
  templateUrl: 'servicos.component.html',
  styleUrls: ['servicos.component.css']
})
export class ServicosComponent implements OnInit {

  constructor() {

  }

  private _id_cliente: number;

  @Input()
  set id_cliente(value) {
    this._id_cliente = value;
  }

  public mygrid: DhtmlXGrid;

  ngOnInit() {

    setTimeout(() => {

      this.mygrid = new DhtmlXGrid('gridboxServico');
      this.mygrid.setImagePath('/dhtmlx/skins/web/imgs/');
      this.mygrid.setHeader('CONTRATO,TIPO,PLACA/COD.,VALOR MENSAL, MENSAL - ISS,ATIVO,BLOQUEADO,INÍCIO');
      this.mygrid.setInitWidths('100,150,120,120,100,100,100,100,100');
      this.mygrid.setColAlign('center,center,center,right,right,center,center,center');
      this.mygrid.setColTypes('ro,ro,ro,ro,ro,ro,ro,ro');
      this.mygrid.setColSorting('int,str,str,int,int,str,str,str');
      this.mygrid.attachHeader('#text_filter,#select_filter,#numeric_filter,#numeric_filter,#numeric_filter,#select_filter,#select_filter,#text_filter');
      this.mygrid.enableAutoHeight(true, 400);
      this.mygrid.setSkin('dhx_web');

      this.mygrid.attachEvent('onRowDblClicked', function () {

      });

      this.mygrid.init();

      this.loadbyCliente(this._id_cliente);

    }, 200);
  }

  loadbyCliente(id_cliente) {
    this.mygrid.load(AppSettings.API_ENDPOINT + '/grid/cliente/contratos/' + id_cliente, () => {
    }, 'json');
  }

}
