import {Directive, AfterViewInit, ElementRef } from '@angular/core';

declare var jQuery: any;

@Directive({
    selector: '[cpfcnpj]',
    host: {
        '(ngModelChange)': 'onInputChange($event)'
    }
})
export class CpfCnpjDirective implements AfterViewInit {


    constructor(private elementRef: ElementRef) { }

    public isCPF: boolean = true;

    ngAfterViewInit() {
        this.setCPF();
        jQuery(this.elementRef.nativeElement).attr('maxlength',18);
    }

    setCPF(){
        this.setClear();
        this.isCPF = true;
        jQuery(this.elementRef.nativeElement).mask("000.000.000-00", {
            placeholder: "___.___.___-__"
        });
    }

    setCNPJ(numero){
        this.setClear();
        this.isCPF = false;
        this.elementRef.nativeElement.value = numero;
        jQuery(this.elementRef.nativeElement).mask("00.000.000/0000-00", {
            placeholder: "__.___.___/____-__"
        });
    }

    setClear(){
        jQuery(this.elementRef.nativeElement).unmask();
    }

    onInputChange($event) {

        if ($event.length > 14 && this.isCPF){
            this.setCNPJ($event);
        }

        if ($event.length <= 14 && ! this.isCPF){
            this.setCPF();
        }

    }

}
