export class Conta{
    public id_contrato_bb: number;
    public id_empresa_fiscal: number;
    public contrato: number;
    public nome_empresa: string;
    public conta_corrente: number;
    public agencia: number;
    public digito_conta: number;
    public registrar: boolean;
}
