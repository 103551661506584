export * from './add-cliente/index';
export * from './login/index';
export * from './principal/index';
export * from './buscar-cliente/index';
export * from './clientes/index';
export * from './orcamento/orcamentos/index';
export * from './orcamento/add-orcamento/index';
export * from './orcamento/orcamento2financeiro/index';
export * from './orcamento/list-aprovar-orcamentos/index';
export * from './orcamento/aprovar-orcamento/index';
export * from './list-aprovar/index';
export * from './contratos/rastreamento/index';
export * from './contratos/manutancao/index';
export * from './novo-manutencao/index';
export * from './novo-manutencao2/index';
export * from './contratos/monitoramento/index';
export * from './contratos/video-monitoramento/index';
export * from './novo-video/index';
export * from './novo-video2/index';
export * from './novo-rastreamento/index';
export * from './novo-rastreamento2/index';
export * from './novo-monitoramento/index';
export * from './novo-monitoramento2/index';
export * from './novo-internet/index';
export * from './novo-internet2/index';
export * from './contratos/cftv/index';
export * from './contratos/internet/index';
export * from './novo-cftv/index';
export * from './novo-cftv2/index';
export * from './aprovar-contratos/index';
export * from './orcamento/mudar-status/mudar-status.component';
export * from './novo-master/novo-master.component';
export * from './novo-master2/novo-master2.component';
export * from './transferir/transferir.component';
export * from './transferir2/transferir2.component';
