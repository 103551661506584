import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../components/toast';
import {LoadingComponent} from '../../components/loading';
import {Contrato, Street, Cliente, ClienteBasico} from '../../schemas/index';
import {ContratoService, ClienteService} from '../../services/index';
import {Router, ActivatedRoute} from '@angular/router';
import {Events} from '../../directives/index';

@Component({
    selector: 'app-novo-master2',
    templateUrl: './novo-master2.component.html',
    styleUrls: ['./novo-master2.component.css']
})
export class NovoMaster2Component implements OnInit, OnDestroy {

    public contrato: Contrato = new Contrato();
    public cliente: Cliente = new Cliente();
    private sub: any;

    constructor(
        private route: ActivatedRoute,
        private scont: ContratoService,
        private scli: ClienteService,
        private event: Events,
        private router: Router) {

    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {

            if ( params['id_contrato'] && params['id_contrato'] != '0' ) {

                LoadingComponent.show();
                this.scont.byPK(params['id_contrato'])
                .then((c: Contrato)=> {
                    if (! c.endereco_instalacao){
                        c.endereco_instalacao = new Street();
                    }
                    this.event.publish("CHECK_UPDATE", true);
                    this.contrato = c;
                    this.loadCliente(c.id_cliente);
                })
                .catch(() => {
                    Toast.error("Problemas para buscar o Contrato!");
                });

            }

            if ( params['id_cliente'] && params['id_cliente'] != '0' ) {
                this.loadCliente(params['id_cliente']);
            }

        });

        this.contrato.tipo_contrato = 'MASTER';

    }

    loadCliente(id_cliente) {
        LoadingComponent.show();
        this.scli.byPK(id_cliente)
        .then((cli: Cliente) => {
            this.cliente = cli;
            this.contrato.cliente = new ClienteBasico();
            this.contrato.cliente.id_cliente = cli.id_cliente;
            this.contrato.cliente.nome = cli.nome;
            this.contrato.id_cliente = cli.id_cliente;
            this.contrato.tipo_contrato = 'MASTER';
            LoadingComponent.hide();
        })
        .catch(() => {
            Toast.error("Problemas para buscar o Cliente!");
        });
    }



    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    save() {
        this.scont.save(this.contrato)
        .then(() => {
            Toast.success("Contrato Salvo com Sucesso!");
            this.router.navigate(['/start']);
        })
        .catch(() => {
            Toast.error("PROBLEMAS para salvar o Contrato!");
        });
    }

    checkEmail () {
        if (this.contrato.boleto_por_email === null) {
            return false;
        }

        if (this.contrato.boleto_por_email) {
            if (this.contrato.emails.length === 0) {
                return false;
            }
        }

        return true;
    }

    checkSave () {

        if (! this.contrato.id_grupo_contrato) {
            return false;
        }

        return this.checkEmail();

    }

}
