import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Cliente} from '../../schemas/index';

@Component({
    selector: 'searchlist-clientes',
    templateUrl: 'searchlist-clientes.component.html',
    styleUrls: ['searchlist-clientes.component.css']
})
export class SearchlistClientesComponent implements OnInit {

    @Input() clientes: Array<Cliente> = [];
    @Output() clicked = new EventEmitter();

    constructor() {

    }

    ngOnInit() {

    }

    stringify(c){
        return JSON.stringify(c);
    }

    send(c){
        this.clicked.emit(JSON.parse(c));
    }

}
