import {Component, Input} from '@angular/core';
import {Produto} from '../../../schemas/index';
import {Events} from '../../../directives/index';
import {Toast} from '../../toast';

declare let jQuery: any;

@Component({
    selector: 'produtos-retirar',
    templateUrl: 'produtos-retirar.component.html',
    styleUrls: ['produtos-retirar.component.css']
})
export class ProdutosRetirarComponent  {

    @Input() produtos: Array<Produto> = [];
    @Input() produtos_retirar: Array<Produto> = [];
    @Input() readonly: boolean = false;
    @Input() title: string = "Produtos para Retirar";


    delProduto(prod: Produto){
        this.produtos_retirar.splice(this.produtos_retirar.indexOf(prod),1);
    }

    public qtde = 0;
    public id_produto = null;

    constructor(public event: Events) {

    }

    setProduto(){
        setTimeout(() => {
            this.produtos.forEach((p) => {
                if (p.id_produto === Number(this.id_produto)){
                    this.addProduto(p);
                }
            })
        }, 300);
    }

    change(){

    }

    addProduto(produto: Produto){

        this.produtos_retirar.forEach((p) => {
            if (p.id_produto == produto.id_produto){
                this.delProduto(p);
            }
        });

        this.produtos_retirar.push(produto);
        this.orderProduto();

    }

    orderProduto(){
        this.produtos_retirar.sort((a,b) => {
            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
        });
    }

}
