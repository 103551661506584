import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';
import {Orcamento} from '../schemas';

@Injectable()
export class OrcamentoService {

    constructor(private http: Http) {

    }

    gridOrcamentoAguardando() {
        return new Promise((resolve, fail) => {
            this
                .http
                .get(AppSettings.API_ENDPOINT + '/grid/orcamentos/aguardando', new MyHeaders().json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    });
        });
    }

    byPK(id_orcamento) {
        return new Promise((resolve) => {
            this
                .http
                .get(AppSettings.API_ENDPOINT + '/orcamento/by-pk/' + id_orcamento, new MyHeaders().json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    }
                );
        });
    }

    excluir(id_orcamento) {
        return new Promise((resolve, fail) => {
            this
                .http
                .delete(AppSettings.API_ENDPOINT + '/orcamento/by-pk/' + id_orcamento, new MyHeaders().json())
                .subscribe(
                    () => {
                        resolve();
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

    save(json: Orcamento) {
        return new Promise((resolve, fail) => {
            if (json.id_orcamento) {
                this
                    .http
                    .put(AppSettings.API_ENDPOINT + '/orcamento/by-pk/' + json.id_orcamento, json, new MyHeaders().json())
                    .subscribe(
                        (data) => {
                            resolve(data.json());
                        },
                        () => {
                            fail();
                        }
                    );
            } else {
                this
                    .http
                    .post(AppSettings.API_ENDPOINT + '/orcamento/by-pk/' + json.id_orcamento, json, new MyHeaders().json())
                    .subscribe(
                        (data) => {
                            resolve(data.json());
                        },
                        () => {
                            fail();
                        }
                    );
            }
        });
    }

    toAprovar(json: Orcamento) {
        return new Promise((resolve, fail) => {
            this
                .http
                .put(AppSettings.API_ENDPOINT + '/orcamento/2aprovar/' + json.id_orcamento, json, new MyHeaders().json())
                .subscribe(
                    () => {
                        resolve();
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

    aprovar(json: Orcamento) {
        return new Promise((resolve, fail) => {
            this
                .http
                .put(AppSettings.API_ENDPOINT + '/orcamento/aprovar/' + json.id_orcamento, json, new MyHeaders().json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

    negar(id_orcamento: number, motivo: string) {
        return new Promise((resolve, fail) => {
            const param = {
                id_orcamento: id_orcamento,
                motivo: motivo
            };
            this
                .http
                .put(AppSettings.API_ENDPOINT + '/orcamento/negar/' + id_orcamento, param, new MyHeaders().json())
                .subscribe(
                    () => {
                        resolve();
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

    list2Aprovar() {
        return new Promise((resolve, fail) => {
            this
                .http
                .get(AppSettings.API_ENDPOINT + '/orcamento/2aprovar/0', new MyHeaders().json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

}
