import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {Parceiro, Setor, TipoSensor} from '../../../schemas';
import {Toast} from '../../toast';
import {TipoSensorService} from '../../../services';

@Component({
    selector: 'setores',
    templateUrl: 'setores.component.html',
    styleUrls: ['setores.component.css']
})
export class SetoresComponent implements OnInit {

    @Input() setores: Array<Setor> = [];
    @Input() readonly = false;
    @Input() produtosProprios = false;
    @Output() updated: EventEmitter<any> = new EventEmitter();

    public tipo_sensor: Array<TipoSensor> = [];

    public setor: Setor = new Setor();

    constructor(private sparc: TipoSensorService) {

    }

    emit () {
        setTimeout(() => {
        this.updated.emit(this.produtosProprios);
        }, 400);
    }

    loadTipoSensor () {
        this.sparc.all()
            .then((rows:  Array<TipoSensor> ) => {
                this.tipo_sensor = rows;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Parceiros!');
            });
    }

    addEmail () {
        this.tipo_sensor.forEach((e) => {
            if (Number(e.id_tipo_sensor) === Number(this.setor.id_tipo_sensor)) {
                this.setor.sensor = e;
            }
        });
        this.delEmail(this.setor);
        this.setores.push(this.setor);
        this.setor = new Setor();
    }

    delEmail(email: Setor) {
        this.setores.forEach((e) => {
            if (Number(e.numero) === Number(email.numero)) {
                this.setores.splice(this.setores.indexOf(e), 1);
            }
        });
    }

    editEmail(email: Setor) {
        this.setor = email;
    }

    ngOnInit() {
        this.loadTipoSensor();
    }

}
