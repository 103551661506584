import {Component, OnInit} from '@angular/core';
import {Cliente} from '../../schemas/index';
import {Router} from '@angular/router';

@Component({
    selector: 'app-novo-cftv',
    templateUrl: 'novo-cftv.component.html',
    styleUrls: ['novo-cftv.component.css']
})
export class NovoCftvComponent implements OnInit {

    public cliente: Cliente;

    constructor(private router: Router) {

    }

    ngOnInit() {

    }

    getCliente(cli: Cliente){
        this.cliente = cli;
        this.router.navigate(['/novo-cftv', cli.id_cliente]);
    }

}
