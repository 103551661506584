import {Component, OnInit} from '@angular/core';
import {Cliente} from '../../schemas/index';
import {Router} from '@angular/router';

@Component({
    selector: 'app-novo-rastreamento',
    templateUrl: 'novo-rastreamento.component.html',
    styleUrls: ['novo-rastreamento.component.css']
})
export class NovoRastreamentoComponent implements OnInit {

    public cliente: Cliente;

    constructor(private router: Router) {

    }

    ngOnInit() {

    }

    getCliente(cli: Cliente){
        this.cliente = cli;
        //console.log(cli);
        this.router.navigate(['/novo-rastreamento', cli.id_cliente]);
    }

}
