declare var dhtmlXGridObject, jQuery: any;

export class DhtmlXGrid{

    private mygrid: any;

    constructor(div){
        this.mygrid = new dhtmlXGridObject(div);
    }

    setImagePath(url: string){
        this.mygrid.setImagePath(url);
    }

    setHeader(text: string){
        this.mygrid.setHeader(text);
    }

    setInitWidths(text: string){
        this.mygrid.setInitWidths(text);
    }

    setColAlign(text: string){
        this.mygrid.setColAlign(text);
    }

    setColTypes(text: string){
        this.mygrid.setColTypes(text);
    }

    setColSorting(text: string){
        this.mygrid.setColSorting(text);
    }

    enableAutoHeight(op1: boolean, op2: number){
        this.mygrid.enableAutoHeight(true,400);
    }

    attachEvent(text: string, fun: any){
        this.mygrid.attachEvent(text, fun);
    }

    attachHeader(text: string){
        this.mygrid.attachHeader(text);
    }

    setSkin(text: string){
        this.mygrid.setSkin(text);
    }

    attachFooter(text: string, arr: Array<string>){
        this.mygrid.attachFooter(text, arr);
    }

    init(){
        this.mygrid.init();
    }

    getSelectedId(): number{
        return this.mygrid.getSelectedId();
    }

    getRowsNum(): number{
        return this.mygrid.getRowsNum();
    }

    filterByAll(){
        this.mygrid.filterByAll();
    }

    clearAll(){
        this.mygrid.clearAll();
    }

    parse(json,fn,format){
        this.mygrid.parse(json, fn, format);
    }

    load(url: string, func: any, tipo: string){
        this.mygrid.load(url, func, tipo);
    }

    getAllItemIds(){
        return this.mygrid.getAllItemIds();
    }

}
