import {Component, OnInit} from '@angular/core';
import {LoadingComponent} from '../../../components/loading';
import {DhtmlXGrid} from '../../../components/dhtmlxgrid';
import {Toast} from '../../../components/toast';
import {ContratoService} from '../../../services/index';
import {Router} from '@angular/router';

declare var jQuery: any;

@Component({
    selector: 'app-cftv',
    templateUrl: 'cftv.component.html',
    styleUrls: ['cftv.component.css']
})
export class CftvComponent implements OnInit {

    constructor(private router: Router, private scont: ContratoService) {

    }

    novo() {
        this.router.navigate(['/novo-cftv']);
    }

    excluir(){

        if (!this.mygrid.getSelectedId()) {
            return Toast.error("Selecione um CFTV!");
        }

        if (confirm("Confirma a Exclusão do CFTV?")) {

            this.scont.excluir(this.mygrid.getSelectedId())
            .then(() => {
                Toast.success("CFTV excluído!");
                this.loadGrid();
            })
            .catch(() => {
                Toast.error("Problemas para excluir o CFTV!");
            });

        }

    }

    public mygrid: DhtmlXGrid;

    ngOnInit() {

        this.mygrid = new DhtmlXGrid('gridbox');
        this.mygrid.setImagePath("/dhtmlx/skins/web/imgs/");
        this.mygrid.setHeader("Nome,Mensalidade,Vendedor,Contrato,Parceiro,Status");
        this.mygrid.setInitWidths("400,120,150,105,180,150");
        this.mygrid.setColAlign("left,center,left,left,center,center");
        this.mygrid.setColTypes("ro,ro,ro,ro,ro,ro");
        this.mygrid.setColSorting("str,int,str,str,str,str");
        this.mygrid.attachEvent("onFilterEnd",() => {
            this.countRow();
        });
        this.mygrid.attachHeader("#text_filter,#numeric_filter,#select_filter,#text_filter,#select_filter,#select_filter");
        this.mygrid.setSkin("dhx_web");
        this.mygrid.attachFooter("<div id='total'>Total de CFTV: 0</div>,#cspan,#cspan,#cspan,#cspan,#cspan",
            ["text-align:left;padding:4px","text-align:right;","text-align:right;","text-align:right;","text-align:right;color:red;"]);

        this.mygrid.attachEvent("onRowDblClicked", () => {
            this.editRastreamento();
        });

        this.mygrid.init();

        let res = () => {
            jQuery('#gridbox').height(jQuery(window).height() - 230);
        };
        res();
        window.onresize = res;

        this.loadGrid();

    }

    editRastreamento() {
        if (this.mygrid.getSelectedId()) {
            return this.router.navigate(['/cftv', this.mygrid.getSelectedId()]);
        }
        Toast.error("Selecione um CFTV!");
    }

    countRow() {
        jQuery('#total').html("Total de CFTV: <b>" + this.mygrid.getRowsNum() + "</b>");
    }

    loadGrid() {

        LoadingComponent.show();
        this.mygrid.clearAll();

        this.scont.gridCFTV()
            .then((data) => {
                this.mygrid.parse(data, () => {
                    this.countRow();
                    this.mygrid.filterByAll();
                    LoadingComponent.hide();
                }, 'json');
            })
            .catch(() => {
                LoadingComponent.hide();
            });

    };

    print(){
        if (this.mygrid.getSelectedId()) {
            window.open('http://ca.centeralarm.com.br/financeiro/print/print_contrato.php?id_contratos_servicos=' + this.mygrid.getSelectedId(),'_blank');
        }
        Toast.error("Selecione um CFTV!");
    }

}
