import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class ContaService {

    constructor(private http:Http) {

    }

    contas() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/contas", header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

    byEmpresaFiscal(idEmpresaFiscal: Number) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/contas/by-empresa-fiscal/" + idEmpresaFiscal, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

}
