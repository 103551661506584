import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class FormaPagamentoService {

    constructor(private http:Http) {

    }

    /**
     *
     * @returns {Promise<Array<any>>}
     */
    ativos() {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/forma_pagamento/ativos", header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

    tipos() {
        return new Promise((resolve) => {
            let tipo = [];
            tipo.push('BOLETO');
            tipo.push('A_VISTA');
            tipo.push('CHEQUE');
            tipo.push('ISENTO');
            tipo.push('DINHEIRO');
            tipo.push('CARTAO');
            tipo.push('PERMUTA');
            resolve(tipo);
        })
    }

}
