import {Component, OnInit, Input} from '@angular/core';
import {ClienteTemporario} from '../../../schemas/cliente_temporario';

@Component({
    selector: 'cliente-temporario',
    templateUrl: 'cliente-temporario.component.html',
    styleUrls: ['cliente-temporario.component.css']
})
export class ClienteTemporarioComponent implements OnInit {

    @Input() cliente_temporario: ClienteTemporario = new ClienteTemporario();
    @Input() readonly: boolean = false;

    constructor() {

    }

    ngOnInit() {

    }

}
