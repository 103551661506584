export class Me {
    public admin: boolean;
    public cargo: string;
    public email: string;
    public id_empresa: number;
    public id_funcionario: number;
    public monitor: boolean;
    public nome: string;
    public patrulheiro: boolean;
    public tecnico: boolean;
    public usuario: string
}
