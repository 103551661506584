export class Vendedor{
    public id_funcionario: number;
    public nome: string;
}

export class Cadastrador extends Vendedor{

}

export class ClienteBasico extends Vendedor{
    public id_cliente: number;
    public nome: string;
}
