import {Component, OnInit, Input} from '@angular/core';
import {AuthService} from '../../services/index';
import {Me} from '../../schemas/index';
import {Auth} from '../../utils/auth';
import {Router}       from '@angular/router';

declare var jQuery: any;

@Component({
    selector: 'header-ca',
    templateUrl: 'header.html',
    providers: [AuthService]
})
export class Header implements OnInit {

    constructor(private sauth:AuthService, private router:Router) {
    }

    public me: Me = new Me();

    _print:boolean = false;

    public qtde_rast: number = 1;
    public qtde_moni: number = 1;
    public qtde_cftv: number = 1;

    @Input()
    set print(p:boolean) {
        this._print = (p) || false;
    }

    get print() {
        return this._print;
    }

    imprimir(){
        window.print();
    }

    kitRastramento(){
        this.qtde_rast = this.qtde_rast | 1;
        window.open("http://ca.centeralarm.com.br/relatorios/kitRastreamento.php?qtde="+ this.qtde_rast, '_blank');
    }

    kitMonitoramento(){
        this.qtde_rast = this.qtde_rast | 1;
        window.open("http://ca.centeralarm.com.br/relatorios/kitMonitoramento.php?qtde="+ this.qtde_rast, '_blank');
    }

    kitInternet(){
        this.qtde_rast = this.qtde_rast | 1;
        window.open("http://ca.centeralarm.com.br/relatorios/kitInternet.php?qtde="+ this.qtde_rast, '_blank');
    }

    kitCFTV(){
        this.qtde_rast = this.qtde_rast | 1;
        window.open("http://ca.centeralarm.com.br/relatorios/kitCFTV.php?qtde="+ this.qtde_rast, '_blank');
    }

    ngOnInit() {
        this.me = Auth.me();
    }

    logout() {
        this.sauth.logout()
        .then(()=> {
            return this.router.navigate(['/login']);
        });
    }

}
