import {Component, OnInit, Input} from '@angular/core';
import {Cliente} from '../../../schemas/index';

@Component({
    selector: 'basico-cliente',
    templateUrl: 'basico-cliente.component.html',
    styleUrls: ['basico-cliente.component.css']
})
export class BasicoClienteComponent implements OnInit {

    @Input() cliente: Cliente = new Cliente();
    @Input() readonly = false;
    @Input() title = 'Dados Básicos';

    constructor() {

    }

    ngOnInit() {

    }

}
