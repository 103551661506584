import {Component, OnInit} from '@angular/core';
import {Cliente} from '../../schemas/index';
import {Router} from '@angular/router';

@Component({
    selector: 'app-transferir',
    templateUrl: './transferir.component.html',
    styleUrls: ['./transferir.component.css']
})
export class TransferirComponent implements OnInit {

    public cliente: Cliente;

    constructor(private router: Router) {

    }

    ngOnInit() {

    }

    getCliente(cli: Cliente){
        this.cliente = cli;
        this.router.navigate(['/transferir', cli.id_cliente]);
    }

}
