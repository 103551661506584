import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';

@Injectable()
export class CepService {

    constructor(private http: Http) {

    }

    getCep(cep: string) {
        return new Promise((resolve, fail) => {
            this.http.get('https://cep.centeralarm.com.br/cep/' + cep, new MyHeaders().json())
            .subscribe(
                (data) => resolve(data.json()),
                (err) => fail(err));
        });
    }

    getBairros(cidade: string) {
        return new Promise((resolve, fail) => {
            this.http.get('https://cep.centeralarm.com.br/bairros/by-cidade/' + cidade, new MyHeaders().json())
                .subscribe(
                    (data) => resolve(data.json()),
                    (err) => fail(err));
        });
    }

}
