import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class ServicosService {

    constructor(private http:Http) {

    }

    combo(tipo: string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/servicos_adicionais/"+ tipo, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                () => {
                    fail();
                }
            );
        });
    }

}
