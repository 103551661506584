declare var jQuery : any;

export class Toast{

    static error(text: string, header: string = "Error"){
        jQuery.toast({
            heading: header,
            text: text,
            showHideTransition: 'fade',
            position: 'top-right',
            icon: 'error'
        })
    }

    static success(text: string){
        jQuery.toast({
            heading: 'Success',
            text: text,
            showHideTransition: 'slide',
            position: 'top-right',
            icon: 'success'
        })
    }

}
