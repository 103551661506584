import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Me} from '../schemas';
import {Auth} from '../utils/auth';

@Injectable()

export class AuthAdmin implements CanActivate {

    constructor(private router:Router) {

    }

    autenticado() {
        return window.localStorage.getItem('auth_key');
    }

    canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot) {

        if (next.url[0].path == 'login') {
            if (window.localStorage.getItem('auth_key')) {
                console.log('You are already logged in');
                return false;
            }
            else
                return true;
        }

        if (this.autenticado() && this.isAdmin())
            return true;

        console.log('You must be logged in');
        this.router.navigate(['/start2']);
        return false;

    }

    isAdmin(){
        let me : Me = Auth.me();
        //console.log(me);
        return me.admin;
    }

}
