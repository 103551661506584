export class AppSettings {
    /*
    public static get API_ENDPOINT(): string {
        return 'http://127.0.0.1:8900';
    }
    */

    public static get API_ENDPOINT(): string {
        return 'https://ca-api.centeralarm.com.br';
    }
}
