export class EmpresaFiscal {

    public id_empresa_fiscal: number;
    public nome: string;
    public razao_social: string;
    public telefone: string;
    public email: string;
    public titulo_venda: string;
    public retensao_iss: number;

}
