
export * from './authmanager';
export * from './authAdmin';
export * from './auth.service';
export * from './cliente.service';
export * from './contrato.service';
export * from './funcionario.service';
export * from './forma_pagamento.service';
export * from './tipo_solicitacao.service';
export * from './status_os.service';
export * from './problemas';
export * from './orcamento.service';
export * from './produtos';
export * from './venda';
export * from './encode';
export * from './cep.service';
export * from './empresa_fiscal.service';
export * from './boleto.service';
export * from './cor.service';
export * from './conta.service';
export * from './parceiros.service';
export * from './grupo_contrato.service';
export * from './rastreador.service';
export * from './gprs.service';
export * from './servicos.service';
export * from './status_venda.service';
export * from './ips.service';
export * from './tipo_sensor.service';

import {FuncionarioService} from './funcionario.service';
import {AuthManager} from './authmanager';
import {AuthAdmin} from './authAdmin';
import {AuthService} from './auth.service';
import {ClienteService} from './cliente.service';
import {CepService} from './cep.service';
import {OrcamentoService} from './orcamento.service';
import {FormaPagamentoService} from './forma_pagamento.service';
import {ProdutosService} from './produtos';
import {EmpresaFiscalService} from './empresa_fiscal.service';
import {ContratoService} from './contrato.service';
import {VendaService} from './venda';
import {BoletoService} from './boleto.service';
import {CorService} from './cor.service';
import {ParceiroService} from './parceiros.service';
import {ContaService} from './conta.service';
import {GrupoService} from './grupo_contrato.service';
import {RastreadorService} from './rastreador.service';
import {GprsService} from './gprs.service';
import {ServicosService} from './servicos.service';
import {StatusVendaService} from './status_venda.service';
import {IpsService} from './ips.service';
import {TipoSensorService} from './tipo_sensor.service';

export let SERVICES_PROVIDERS = [
    FuncionarioService,
    ClienteService,
    AuthManager,
    ServicosService,
    BoletoService,
    GrupoService,
    GprsService,
    AuthAdmin,
    ContratoService,
    CorService,
    RastreadorService,
    FormaPagamentoService,
    CepService,
    ParceiroService,
    EmpresaFiscalService,
    VendaService,
    ProdutosService,
    OrcamentoService,
    ContaService,
    AuthService,
    StatusVendaService,
    IpsService,
    TipoSensorService
];
