export class ValoresVenda{

    public entrada : number;
    public num_parcelas : number;
    public valor_parcela : number;
    public custo : number;
    public lucro : number;
    public impostos : number;
    public comissao_tecnico : number;
    public comissao_vendedor : number;

}
