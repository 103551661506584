import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class VendaService {

    constructor(private http:Http) {

    }

    byPK(id_venda) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/venda/by-pk/" + id_venda, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                () => {
                    fail();
                }
            );
        });
    }

    mudarStatus(id_venda, id_status) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.put(AppSettings.API_ENDPOINT + "/venda/status-venda/" + id_venda, {id_status_venda: id_status}, header.json())
            .subscribe(
                () => {
                    resolve();
                },
                () => {
                    fail();
                }
            );
        });
    }

    /**
     *
     * @returns {Promise<Array<any>>}
     */
    getFinanceiro(id_venda) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/venda/financeiro/" + id_venda, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

}
