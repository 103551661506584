import {Component, OnInit, Input} from '@angular/core';
import {DhtmlXGrid} from '../../../components/dhtmlxgrid';
import {AppSettings} from '../../../app.settings';
import {Router} from '@angular/router';

@Component({
    selector: 'vendas',
    templateUrl: 'vendas.component.html',
    styleUrls: ['vendas.component.css']
})
export class VendasComponent implements OnInit {

    constructor(private router: Router) {

    }

    private _id_cliente: number;

    @Input()
    set id_cliente( value ){
        this._id_cliente = value;
    }

    public mygrid: DhtmlXGrid;

    ngOnInit() {

        setTimeout(() => {

            this.mygrid = new DhtmlXGrid('gridboxVendas');
            this.mygrid.setImagePath("/dhtmlx/skins/web/imgs/");
            this.mygrid.setHeader("Número,Data,Valor,Tipo de Pagamento,Vendedor");
            this.mygrid.setInitWidths("80,80,80,200,200");
            this.mygrid.setColAlign("center,center,right,center,center");
            this.mygrid.setColTypes("ro,ro,ro,ro,ro");
            this.mygrid.attachHeader("#text_filter,#text_filter,#numeric_filter,#select_filter,#select_filter");
            this.mygrid.setColSorting("int,dateSort,str,int,str");
            this.mygrid.enableAutoHeight(true,400);
            this.mygrid.setSkin("dhx_web");

            this.mygrid.attachEvent("onRowDblClicked", () => {
                this.venda();
            });

            this.mygrid.init();

            this.loadbyCliente();

        },100);

    }

    venda(){
        this.router.navigate(['/venda', this.mygrid.getSelectedId()]);
    }

    loadbyCliente(){
        this.mygrid.load(AppSettings.API_ENDPOINT +  "/grid/cliente/vendas/"+ this._id_cliente,()=>{},'json');
    };

}
