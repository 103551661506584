import {DadosInternet} from './dados_internet';
import {Produto} from './produto';
import {Setor} from './setor';
import {Street} from './street';
import {Cadastrador, ClienteBasico, Vendedor} from './vendedor';
import {DadosVeiculo} from './dados_veiculo';
import {DadosProprietario} from './dados_proprietario';

export class Contrato {

    public id_contratos_servicos: number;
    public mao_de_obra = 0;
    public taxa_instalacao = 0;
    public id_contrato_bb: number;
    public data_cadastro: string;
    public dia_vencimento: number;
    public valor_parcela: number;
    public id_parceiro: number;
    public id_vendedor: number = null;
    public id_cliente: number;
    public desconto = 0;
    public imposto: any = true;
    public retensao_iss: any = false;
    public criar_os: any = true;
    public contas_a_receber: number;
    public pagamento: string;
    public boleto_por_email: any = true;
    public prepago: any = true;
    public id_contratos_servicos_mastar: number = null;
    public chave_moni: number = null;
    public contrato_pago: any = true;
    public basico_mensal = 0;
    public ajuste = 0;
    public id_grupo_contrato: number;
    public comodato: boolean;
    public portaria: any = false;
    public video_monitoramento: any = false;
    public produtos_proprios: any = false;
    public ativo: boolean;
    public id_rastreador: number;
    public id_gprs: number;
    public contrato: string;
    public obs: string;
    public obs_os: string;
    public cod_cliente: number;
    public dados_internet: DadosInternet = new DadosInternet();
    public carencia_mensal = 12;
    public nome_fantasia: string;
    public tipo_contrato: string;
    public id_contratos_servicos_transferido: any;
    public produtos: Array<Produto> = [];
    public setores: Array<Setor> = [];
    public servicos_adicionais: Array<any> = [];
    public emails: Array<string> = [];
    public endereco_instalacao: Street = new Street();
    public vendedor: Vendedor = new Vendedor();
    public cadastrador: Cadastrador = new Cadastrador();
    public cliente: ClienteBasico = new ClienteBasico();
    public dados_veiculo: DadosVeiculo = new DadosVeiculo();
    public dados_proprietario: DadosProprietario = new DadosProprietario();
    public placa: string;
    public id_ips_cadastrados: number;
    public id_empresa_fiscal = 1;
}

export class ContratoMaster {
    public id_contratos_servicos: number;
    public nome: string;
}
