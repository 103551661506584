import {Directive, ElementRef, AfterViewInit} from '@angular/core';

declare var jQuery: any;

@Directive({
    selector: '[ngModel][date]'
})
export class DataDirective implements AfterViewInit {

    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit() {
        jQuery(this.elementRef.nativeElement).mask('00-00-0000', {
            placeholder: '__-__-____'
        });
        jQuery(this.elementRef.nativeElement).attr('maxlength', 10);
    }

}
