export class ServicoAdicional{
    public id_servicos_monitoramento: number;
    public nome: string;
    public valor: number;
    public add: number;
    public valor_minimo: number;
    public texto: number;
    public qtde: number;
    public upload: number;
    public download: number;
    public internet_dedicado: boolean;
    public limit_dedicado: number;
}
