import {Component, OnInit} from '@angular/core';
import {Toast} from '../../../components/toast';
import {Router} from '@angular/router';
import {Orcamento} from '../../../schemas/index';
import {OrcamentoService} from '../../../services/index';

@Component({
    selector: 'app-list-aprovar-orcamentos',
    templateUrl: 'list-aprovar-orcamentos.component.html',
    styleUrls: ['list-aprovar-orcamentos.component.css']
})
export class ListAprovarOrcamentosComponent implements OnInit {

    public orcamentos: Array<Orcamento> = [];

    constructor(private sorc: OrcamentoService, private router: Router) {

    }

    edit(id_orcamento){
        this.router.navigate(['/orcamento', id_orcamento]);
    }

    aprovar(id_orcamento){
        this.router.navigate(['/aprovar-orcamento', id_orcamento]);
    }

    ngOnInit() {

        this.sorc.list2Aprovar()
            .then((rows: Array<Orcamento>) => {
                rows.sort(function (a, b) {
                    if (a.cliente.nome > b.cliente.nome) {
                        return 1;
                    }
                    if (a.cliente.nome < b.cliente.nome) {
                        return -1;
                    }
                    return 0;
                });
                this.orcamentos = rows;
            })
            .catch(() => {
                Toast.error("Problemas para buscar os Orçamentos.");
            });

    }

}
