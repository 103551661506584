import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../components/toast';
import {LoadingComponent} from '../../components/loading';
import {Contrato, Produto, Conta, Street, ContratoMaster} from '../../schemas/index';
import {ContratoService, ProdutosService, ContaService} from '../../services/index';
import {Router, ActivatedRoute} from '@angular/router';
import {Events} from '../../directives/index';

@Component({
    selector: 'app-aprovar-contratos',
    templateUrl: 'aprovar-contratos.component.html',
    styleUrls: ['aprovar-contratos.component.css']
})
export class AprovarContratosComponent implements OnInit, OnDestroy {

    public contrato: Contrato = new Contrato();
    public contas: Array<Conta> = [];
    public produtosQtdeError = false;
    public aprovando = false;
    public masters: Array<ContratoMaster> = [];
    private sub: any;

    public forma_pagamento = [
        {nome: 'Boleto Bancário', value: 'BOLETO'},
        {nome: 'Cartão em 12x', value: 'CARTAO12'}
    ];

    constructor(
        private route: ActivatedRoute,
        private scont: ContratoService,
        private sprod: ProdutosService,
        private router: Router,
        private event: Events,
        private sconta: ContaService
    ) {
    }

    loadContas(idEmpresaFiscal: Number) {

        this.sconta.byEmpresaFiscal(idEmpresaFiscal)
            .then((rows: Array<Conta>) => {
                this.contas = rows;
                /*
                this.contas = rows.filter((m: Conta) => {
                    return m.registrar;
                });
                */
            })
            .catch(() => {
                Toast.error('Problemas para buscar as Contas!');
            });
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {

            if (params['id_contrato'] && params['id_contrato'] != '0') {

                LoadingComponent.show();
                this.scont
                    .byPK(params['id_contrato'])
                    .then((c: Contrato) => {

                        if (!c.endereco_instalacao) {
                            c.endereco_instalacao = new Street();
                        }

                        if (!c.id_empresa_fiscal) {
                            if (c.tipo_contrato === 'RASTREAMENTO' || c.tipo_contrato === 'INTERNET') {
                                c.id_empresa_fiscal = c.id_empresa_fiscal || 2;
                            } else {
                                c.id_empresa_fiscal = c.id_empresa_fiscal || 1;
                            }
                        }

                        this.contrato = c;
                        LoadingComponent.hide();

                        // if(this.contrato.tipo_contrato != 'INTERNET'){
                        this.event.publish('GET_IP', this.contrato.id_ips_cadastrados);
                        // }

                        this.contrato.criar_os = 1;

                        this.loadMasters();
                        this.loadContas(c.id_empresa_fiscal);

                        if (this.contrato.tipo_contrato === 'INTERNET') {
                            this.contrato.produtos.forEach((p) => p.qtde_estoque = 5);
                        } else {
                            this.sprod.qtdeByContrato(this.contrato.id_contratos_servicos)
                                .then((data: Array<Produto>) => {
                                    data.forEach((pa) => {
                                        this.contrato.produtos.forEach((p) => {
                                            if (pa.id_produto === p.id_produto) {
                                                p.qtde_estoque = pa.qtde;
                                            }
                                        });
                                    });
                                    this.contrato.produtos.forEach((p: Produto) => {
                                        if (p.qtde_estoque < p.qtde) {
                                            this.produtosQtdeError = true;
                                        }
                                    });

                                })
                                .catch(() => {
                                    Toast.error('Problemas para buscar os dados do Estoque!');
                                });
                        }

                    })
                    .catch(() => {
                        Toast.error('Problemas para buscar o Contrato!');
                    });

            }

        });

    }

    loadMasters() {
        this.scont
            .masters()
            .then((ven: Array<ContratoMaster>) => this.masters = ven)
            .catch(() => Toast.error('Problemas para buscar os Masters!'));
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    checkAprova() {

        if (!this.contrato.criar_os && this.contrato.criar_os !== 0) {
            return false;
        }

        if (!this.contrato.contas_a_receber && this.contrato.contas_a_receber !== 0) {
            return false;
        }

        if (!this.contrato.pagamento) {
            return false;
        }

        if (!this.contrato.id_contrato_bb) {
            return false;
        }

        return true;
        /*
        if (!this.contrato.id_contratos_servicos_transferido) {
            if (this.contrato.tipo_contrato === 'RASTREAMENTO') {

                if (!this.contrato.id_rastreador) {
                    return false;
                }

            }
            return (!this.produtosQtdeError);
        } else {
            return true;
        }

         */

    }

    aprovar() {
        this.contrato.retensao_iss = JSON.parse(this.contrato.retensao_iss);
        this.contrato.criar_os = Number(this.contrato.criar_os);
        if (! this.aprovando && this.checkAprova()) {
            this.aprovando = true;
            this.scont
                .aprovar(this.contrato)
                .then(() => {
                    this.aprovando = false;
                    Toast.success('Contrato Aprovado');
                    this.router.navigate(['/start']);
                })
                .catch(() => {
                    this.aprovando = false;
                    Toast.error('NÃO Aprovado');
                });
        }
    }

}
