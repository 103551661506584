import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class IpsService {

    constructor(private http:Http) {

    }

    combo(id_ips_cadastrados: number) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/internet/ips/combo/" + id_ips_cadastrados, header.json())
                .subscribe((data) => resolve(data.json()), () => fail());
        });
    }

}
