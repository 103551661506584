import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';

import {CurrencyMaskModule} from 'ng2-currency-mask';
import {CurrencyMaskConfig, CURRENCY_MASK_CONFIG} from 'ng2-currency-mask/src/currency-mask.config';
import {MaterializeModule} from 'angular2-materialize';
import {AuthManager, SERVICES_PROVIDERS} from './services';
import {AppComponent} from './app.component';
import {routing} from './app.routes';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: true,
    decimal: ',',
    precision: 2,
    prefix: 'R$ ',
    suffix: '',
    thousands: '.'
};

import {
    AddClienteComponent,
    AprovarContratosComponent,
    BuscarClienteComponent,
    LoginComponent,
    ListAprovarComponent,
    PrincipalComponent,
    RastreamentoComponent,
    MonitoramentoComponent,
    CftvComponent,
    NovoCftvComponent,
    NovoCftv2Component,
    NovoRastreamentoComponent,
    NovoRastreamento2Component,
    NovoMonitoramentoComponent,
    NovoMonitoramento2Component,
    OrcamentosComponent,
    Orcamento2financeiroComponent,
    ListAprovarOrcamentosComponent,
    AprovarOrcamentoComponent,
    ClientesComponent,
    AddOrcamentoComponent,
    InternetComponent,
    NovoInternetComponent,
    NovoInternet2Component,
    VideoMonitoramentoComponent,
    NovoVideoComponent,
    NovoVideo2Component,
    ManutancaoComponent,
    NovoContratroManutencaoComponent,
    NovoManutencao2Component,
    TransferirComponent,
    Transferir2Component
} from './pages/index';

import {PrintOrcamentoComponent} from './pages/print-orcamento';

import {CepDirective, CpfCnpjDirective, MONEY_DIRECTIVES, TelefoneDirective, DataDirective, Events, UpperCaseDirective} from './directives';
import {
    Breadcrumb,
    Header,
    Voltar,
    CLIENTE_DIRECTIVES,
    CONTRATOS_DIRECTIVES,
    ORCAMENTOS_DIRECTIVES,
    LoadingComponent,
    SearchClientesComponent,
    Orcamento2Financeiro,
    StreetComponent,
    SearchlistClientesComponent
} from './components';
import {NegarVendaComponent} from './components';
import {NewlinePipe} from './pipes/newline.pipe';
import {MudarStatusComponent} from './pages';
import {NovoMasterComponent} from './pages';
import {NovoMaster2Component} from './pages';
import {DadosTecnicosInternetComponent} from './components/contratos/dados-tecnicos-internet/dados-tecnicos-internet.component';

@NgModule({
    declarations: [
        AppComponent,
        AddClienteComponent,
        SearchlistClientesComponent,
        SearchClientesComponent,
        AprovarContratosComponent,
        BuscarClienteComponent,
        LoginComponent,
        ListAprovarComponent,
        PrincipalComponent,
        PrintOrcamentoComponent,
        RastreamentoComponent,
        MonitoramentoComponent,
        CftvComponent,
        NovoCftvComponent,
        NovoCftv2Component,
        NovoRastreamentoComponent,
        NovoRastreamento2Component,
        NovoMonitoramentoComponent,
        NovoMonitoramento2Component,
        OrcamentosComponent,
        Orcamento2financeiroComponent,
        ListAprovarOrcamentosComponent,
        AprovarOrcamentoComponent,
        ClientesComponent,
        Orcamento2Financeiro,
        AddOrcamentoComponent,
        CepDirective,
        CpfCnpjDirective,
        UpperCaseDirective,
        MONEY_DIRECTIVES,
        TelefoneDirective,
        DataDirective,
        Breadcrumb,
        Header,
        Voltar,
        CLIENTE_DIRECTIVES,
        CONTRATOS_DIRECTIVES,
        ORCAMENTOS_DIRECTIVES,
        LoadingComponent,
        StreetComponent,
        NegarVendaComponent,
        NewlinePipe,
        MudarStatusComponent,
        NovoMasterComponent,
        NovoMaster2Component,
        InternetComponent,
        NovoInternetComponent,
        NovoInternet2Component,
        VideoMonitoramentoComponent,
        NovoVideoComponent,
        NovoVideo2Component,
        ManutancaoComponent,
        NovoContratroManutencaoComponent,
        NovoManutencao2Component,
        DadosTecnicosInternetComponent,
        TransferirComponent,
        Transferir2Component
    ],
    imports: [
        BrowserModule,
        FormsModule,
        MaterializeModule,
        HttpModule,
        routing,
        CurrencyMaskModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        SERVICES_PROVIDERS,
        AuthManager,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        Events
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
