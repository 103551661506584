import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class TipoSensorService {

    constructor(private http: Http) {

    }

    all() {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/tipo-sensores", header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    () => {
                        fail();
                    }
                );
        });
    }

}
