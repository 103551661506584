import {Component, OnInit, Input} from '@angular/core';
import {Auth} from '../utils/auth';
import {Me} from '../schemas/index';
import {Router}       from '@angular/router';

@Component({

    selector: 'breadcrumb-ca',
    template: `<nav  class="noprint" style="margin-top: 64px; position: fixed;width: 100%;z-index:400;">
      <div class="nav-wrapper red darken-2" style="padding-left: 20px;padding-right: 20px; ">
    <div class="col s12">
    <a class="breadcrumb">{{titulo}}</a>
    <a *ngIf="me.admin && id_contrato && editar && tipo_contrato" (click)="editarContrato()" class="right" style="cursor: pointer"><i class="material-icons left">mode_edit</i> Editar</a>
    <a *ngIf="me.admin && id_orcamento && editar" (click)="editarOrcamento()" class="right" style="cursor: pointer"><i class="material-icons left">mode_edit</i> Editar</a>
    <a *ngIf="me.admin && id_orcamento && aprovar" (click)="aprovarOrcamento()" class="right" style="cursor: pointer"><i class="material-icons left">check</i> Aprovar</a>
    <a *ngIf="me.admin && id_contrato && aprovar" (click)="aprovarContrato()" class="right" style="cursor: pointer"><i class="material-icons left">check</i> Aprovar</a>
    </div></div></nav>`
})
export class Breadcrumb implements OnInit {

    public me : Me = new Me();

    @Input() aprovar : boolean = false;
    @Input() editar : boolean = false;
    @Input() id_contrato : number;
    @Input() id_orcamento : number;
    @Input() tipo_contrato : string;

    constructor(private router:Router) {

    }

    aprovarContrato(){
        this.router.navigate(['/aprovar-contrato',this.id_contrato]);
    }

    editarOrcamento(){
        this.router.navigate(['/orcamento', this.id_orcamento]);
    }

    editarContrato(){
        switch (this.tipo_contrato){
            case 'MONITORAMENTO':
                this.router.navigate(['/monitoramento', this.id_contrato]);
                break;
            case 'RASTREAMENTO':
                this.router.navigate(['/rastreamento', this.id_contrato]);
                break;
            case 'CFTV':
                this.router.navigate(['/cftv', this.id_contrato]);
                break;
            case 'INTERNET':
                this.router.navigate(['/internet', this.id_contrato]);
                break;
            case 'VIDEO':
                this.router.navigate(['/video-monitoramento', this.id_contrato]);
                break;
            case 'MANUTENCAO':
                this.router.navigate(['/manutencao', this.id_contrato]);
                break;
        }
    }

    aprovarOrcamento(){
        this.router.navigate(['/aprovar-orcamento', this.id_orcamento]);
    }

    _titulo:string = '';

    @Input()
    set titulo(name:string) {
        this._titulo = (name && name.trim()) || '';
    }

    get titulo() {
        return this._titulo;
    }

    ngOnInit() {
        this.me = Auth.me();
    }

}
