import {Component, OnInit, Input} from '@angular/core';
import {OrcamentoService, ContratoService} from '../../services/index';
import {Events} from '../../directives/index';
import {Toast} from '../../components/toast';

@Component({
    selector: 'negar-venda',
    templateUrl: './negar-venda.component.html',
    styleUrls: ['./negar-venda.component.css']
})
export class NegarVendaComponent implements OnInit {

    @Input() id_orcamento : number;
    @Input() id_contratos_servicos : number;
    @Input() title: string = "NEGAR";

    public motivo : string;

    public rand: any;

    constructor(
        private sorc: OrcamentoService,
        private scont: ContratoService,
        private events: Events
    ) {
        this.rand = this.random().toString().replace('.','');
    }

    random(){
        return Math.random();
    }

    ngOnInit() {

    }

    negar(){
        if (this.id_contratos_servicos){
            this.negarContrato();
        } else if (this.id_orcamento){
            this.negarOrcamento();
        }
    }

    private negarContrato(){
        this.scont.negar(this.id_contratos_servicos, this.motivo)
        .then(() => {
            this.events.publish('RELOAD-APROVAR-CONTRATO', true);
            Toast.success("Contrato Negado!");
        })
        .catch(() => {
            Toast.error("Contrato NÃO Negado!");
        })
    }

    private negarOrcamento(){
        this.sorc.negar(this.id_orcamento, this.motivo)
            .then(() => {
                this.events.publish('RELOAD-APROVAR-ORCAMENTO', true);
                Toast.success("Orcamento Negado!");
            })
            .catch(() => {
                Toast.error("Orcamento NÃO Negado!");
            })
    }

}
