import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class ProdutosService {

    constructor(private http:Http) {

    }

    internos() {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/produtos/interno", header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

    qtdeByOrcamento(id_orcamento: number) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/produtos/qtde-orcamento/"+ id_orcamento, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

    qtdeByOrcamentoComodato(id_orcamento: number) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/produtos/qtde-orcamento-comodato/"+ id_orcamento, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

    qtdeByContrato(id_contrato: number) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/produtos/qtde-contrato/"+ id_contrato, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

}
