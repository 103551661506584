import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Produto, Orcamento} from '../../schemas/index';
import {ProdutosService} from '../../services/index';
import {Events} from '../../directives/index';
import {Toast} from '../../components/toast';

declare let jQuery: any;

@Component({
    selector: 'produtos',
    templateUrl: 'produtos.component.html',
    styleUrls: ['produtos.component.css']
})
export class ProdutosComponent implements OnInit, OnDestroy {

    @Input() produtos: Array<Produto> = [];
    @Input() id_empresa_fiscal: number;
    @Input() id_orcamento: number;
    @Input() id_contratos_servicos: number;
    @Input() tipo_venda: string;
    @Input() aprovado = false;
    @Input() aprovacao = false;
    @Input() readonly = false;
    @Input() title = 'Produtos';

    public all_produtos: Array<Produto> = [];
    public filtrado: Array<any> = [];
    public nome = '';
    public cod = '';
    public qtde = 0;
    public produto: Produto;

    delProduto(prod: Produto) {
        this.produtos.splice(this.produtos.indexOf(prod), 1);
        this.event.publish('UPDATE::TAXA', true);
    }

    ngOnDestroy(): void {
        this.event.unsubscribe('CHECK_UPDATE', this.loadQtdeByOrcamento);
    }

    constructor(
        private sprod: ProdutosService,
        public event: Events
    ) {

    }

    loadQtdeByOrcamento = () => {

        setTimeout(() => {

            const process = (data: Array<Produto>) => {

                data.forEach((pa) => {
                    this.produtos.forEach((p) => {
                        if (Number(pa.id_produto) === Number(p.id_produto)) {
                            p.qtde_estoque = pa.qtde;
                        }
                    });
                });

            };

            console.log(this.tipo_venda);

            if (this.id_orcamento || this.id_contratos_servicos) {
                console.log(this.tipo_venda);
                switch (this.tipo_venda) {
                    case 'VENDA':
                        this.sprod.qtdeByOrcamento(this.id_orcamento)
                            .then(process)
                            .catch(() => Toast.error('Problemas para buscar os dados do Estoque!'));
                        break;
                    case 'INTERNET':

                        break;
                    case 'VIDEO':
                    case 'MONITORAMENTO':
                    case 'RASTREAMENTO':
                    case 'CFTV':
                        this.sprod.qtdeByContrato(this.id_contratos_servicos)
                            .then(process)
                            .catch(() => Toast.error('Problemas para buscar os dados do Estoque!'));
                        break;
                    default:
                        this.sprod.qtdeByOrcamentoComodato(this.id_orcamento)
                            .then(process)
                            .catch(() => Toast.error('Problemas para buscar os dados do Estoque!'));
                        break;
                }
            }

        }, 300);
    }

    setProduto(produto: Produto) {

        this.produto = new Produto();
        this.produto.comissao_vendedor = produto.comissao_real;
        this.produto.custo = produto.custo_real;
        this.produto.lucro = produto.lucro_real;
        this.produto.nome = produto.nome;
        this.produto.impostos = produto.icms;
        this.produto.qtde = 1;
        this.produto.valor = produto.valor_produto;
        this.produto.valor_original = produto.valor_produto;
        this.produto.id_produto = produto.id_produto;
        this.produto.codigo_produto = produto.codigo_produto;
        this.produto.id_empresa_fiscal = produto.id_empresa_fiscal;
        this.produto.taxa_instalacao = produto.taxa_instalacao;
        this.qtde = produto.qtde;

    }

    addProduto() {

        if (!this.produto.qtde) {
            Toast.error('Informe a QTDE!');
            return;
        }

        if (this.produto.valor_original > this.produto.valor) {
            Toast.error('O valor não pode ser menor!');
            return;
        }

        this.produtos.forEach((p) => {
            if (Number(p.id_produto) === Number(this.produto.id_produto)) {
                this.delProduto(p);
            }
        });

        this.produtos.push(this.produto);
        this.orderProduto();
        this.event.publish('UPDATE::TAXA', true);
        this.produto = null;
        this.loadQtdeByOrcamento();

    }

    orderProduto() {
        this.produtos.sort((a, b) => {
            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
        });
    }

    digitado() {

        if (this.nome.length === 0 && this.cod.length === 0) {
            this.filtrado = this.all_produtos.filter(
                (book) => {
                    return Number(this.id_empresa_fiscal) === Number(book.id_empresa_fiscal);
                }
            );
        } else {

            let fnome = false;
            let fcod = false;

            if (this.nome.length) {
                fnome = true;
            }

            if (this.cod.length) {
                fcod = true;
            }

            this.filtrado = this.all_produtos.filter(
                (book) => {
                    let opt1 = book.nome.toLowerCase().indexOf(this.nome.toLowerCase()) > -1;
                    let opt3 = book.codigo_produto == this.cod;
                    let opt2 = Number(this.id_empresa_fiscal) === Number(book.id_empresa_fiscal);
                    // console.log(opt3);
                    return ((opt1 && fnome) || (opt3 && fcod)) && opt2;
                }
            );

        }

    }

    ngOnInit() {
        this.getProduto();
        setTimeout(() => this.orderProduto(), 300);
        this.event.subscribe('CHECK_UPDATE', this.loadQtdeByOrcamento);
    }

    getProduto() {

        if (this.tipo_venda === 'INTERNET') {

            const p = new Produto();
            p.nome = 'Roteador wireless';
            p.codigo_produto = 'WIFI';
            p.id_empresa_fiscal = 2;
            p.qtde_estoque = 10;
            p.id_produto = 1;
            p.valor = 0;
            p.taxa_instalacao = 0;

            this.all_produtos.push(p);

            const p3 = new Produto();
            p3.nome = 'Rede Óptica de Distribuição';
            p3.codigo_produto = 'ONU';
            p3.id_empresa_fiscal = 2;
            p3.qtde_estoque = 10;
            p3.id_produto = 3;
            p3.valor = 0;
            p3.taxa_instalacao = 0;

            this.all_produtos.push(p3);

            const p2 = new Produto();
            p2.nome = 'Antena de rádio receptor';
            p2.codigo_produto = 'RADIO';
            p2.id_empresa_fiscal = 2;
            p2.qtde_estoque = 10;
            p2.id_produto = 2;
            p2.valor = 0;
            p2.taxa_instalacao = 0;

            this.all_produtos.push(p2);

            const p4 = new Produto();
            p4.nome = 'Fonte POE';
            p4.codigo_produto = 'FONTE';
            p4.id_empresa_fiscal = 2;
            p4.qtde_estoque = 10;
            p4.id_produto = 2;
            p4.valor = 0;
            p4.taxa_instalacao = 0;

            this.all_produtos.push(p4);

            this.digitado();

        } else {

            this.sprod.internos()
                .then((rows: Array<any>) => {
                    this.all_produtos = rows;
                    this.all_produtos.sort((a, b) => {
                        return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                    });
                    this.digitado();
                })
                .catch((err) => console.error(err));
        }
    }

}
