import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Orcamento, Vendedor, FormaPagamento, EmpresaFiscal, Me, Parceiro} from '../../../schemas/index';
import {FuncionarioService, FormaPagamentoService, EmpresaFiscalService, ParceiroService} from '../../../services/index';
import {Events} from '../../../directives/index';
import {Toast} from '../../../components/toast';
import {Auth} from '../../../utils/auth';

declare let JSON: any;

@Component({
    selector: 'financeiro-orcamento',
    templateUrl: 'financeiro-orcamento.component.html',
    styleUrls: ['financeiro-orcamento.component.css']
})
export class FinanceiroOrcamentoComponent implements OnInit, OnDestroy {

    ngOnDestroy(): any {
        this.event.unsubscribe('UPDATE::TAXA', this.executeTaxa);
    }

    @Input() orcamento: Orcamento = new Orcamento();
    @Input() readonly: boolean = false;

    public vendores: Array<Vendedor> = [];
    public formas: Array<FormaPagamento> = [];
    public empresas: Array<EmpresaFiscal> = [];
    public parceiros: Array<Parceiro> = [];

    public me: Me;

    public tipovenda: any = [
        {name: 'VENDA', value: 'VENDA'},
        {name: 'COMODATO COM MÃO DE OBRA', value: 'COMODATO'},
        {name: 'DOACAO AO CLIENTE', value: 'DOACAO'}
    ];

    constructor(
        public event: Events,
        sfunc: FuncionarioService,
        sempresa: EmpresaFiscalService,
        sparceiro: ParceiroService,
        sforma: FormaPagamentoService) {

        this.me = Auth.me();

        sempresa.all()
            .then((row: Array<EmpresaFiscal>) => {
                this.empresas = row;
            })
            .catch(() => {
                Toast.error('Problemas para buscar as empresas fiscais!');
            });

        sfunc.vendedores()
            .then((ven: Array<Vendedor>) => {
                this.vendores = ven;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os vendedores!');
            });

        sparceiro.parceiros()
            .then((ven: Array<Parceiro>) => {
                this.parceiros = ven;
            })
            .catch(() => {
                Toast.error('Problemas para buscar os Parceiros!');
            });

        sforma.ativos()
            .then((f: Array<FormaPagamento>) => {
                this.formas = f;
                this.change();
            })
            .catch(() => {
                Toast.error('Problemas para buscar as Formas de Pagamento!');
            });
    }

    ngOnInit() {
        this.event.subscribe('UPDATE::TAXA', this.executeTaxa);
    }

    executeTaxa = () => {
        this.sumTaxaInstalacao();
    };

    changeEmpresa() {
        this.orcamento.produtos = [];
        setTimeout(() => {
            this.event.publish('CHECK_UPDATE', true);
            this.sumTaxaInstalacao();
        }, 300);
    }

    changeVendedor() {
        setTimeout(() => {
            this.vendores.forEach((f) => {
                if (f.id_funcionario == this.orcamento.id_vendedor) {
                    this.orcamento.vendedor = f;
                }
            });
        }, 300);
    }

    change() {
        setTimeout(() => {
            this.formas.forEach((f) => {
                if (f.id_forma_pagamento == this.orcamento.id_forma_pagamento) {
                    this.orcamento.forma_pagamento = f;
                }
            });
            if (!this.orcamento.forma_pagamento.habilita_entrada) {
                this.orcamento.valores_venda.entrada = 0;
            }
            this.event.publish('CHECK_UPDATE', true);
            this.sumTaxaInstalacao(false);
        }, 300);
    }

    sumTaxaInstalacao(updateEntrada: boolean = true) {
        setTimeout(() => {
            Orcamento.sumTaxaInstalacao(this.orcamento, updateEntrada);
        }, 300);
    }

}
