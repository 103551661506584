import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ProdutosService}          from '../../services/index';
import {Produto} from '../../schemas/index';

@Component({
    selector: 'search-produto',
    templateUrl: 'search-produto.html',
    styleUrls: ['search-produto.css']
})
export class SearchProduto implements OnInit {

    @Output()
    produto_selecionado : EventEmitter<any> = new EventEmitter();

    public produtos: Array<any> = [];

    public filtrado : Array<any> = [];

    public nome = '';

    public produto: Produto;

    constructor(
        private sprod: ProdutosService
    ) {

    }

    setProduto(produto){
        this.produto = produto;
    }

    digitado() {
        if (this.nome.length == 0){
            this.filtrado = this.produtos;
        }
        this.filtrado = this.produtos.filter(
            book =>  book.nome.toLowerCase().indexOf(this.nome.toLowerCase()) > -1
        );
    }

    ngOnInit() {
        this.getProduto();
    }

    getProduto(){
        this.sprod.internos()
        .then((rows: Array<any>) => {
            this.produtos = rows;
            this.digitado();
        })
    }

}
