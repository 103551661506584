import {Headers} from '@angular/http';

export class MyHeaders {

    constructor() {

    }

    private static _getHeader() {
        let headers = new Headers();
        let token = window.localStorage.getItem('auth_key');
        if (token) {
            headers.append('authorization', 'JWT ' + token);
        }
        return headers;
    }

    json() {
        let headers = MyHeaders._getHeader();
        headers.append('Content-Type', 'application/json');
        return {headers: headers};
    }

    form() {
        let headers = MyHeaders._getHeader();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return {headers: headers};
    }

}
