import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../components/toast';
import {LoadingComponent} from '../../components/loading';
import {Contrato, Vendedor, ContratoMaster, Street, Cliente, ClienteBasico} from '../../schemas/index';
import {ContratoService, FuncionarioService, GprsService, ClienteService} from '../../services/index';
import {Router, ActivatedRoute} from '@angular/router';
import {Events} from '../../directives/index';

@Component({
    selector: 'app-novo-video2',
    templateUrl: 'novo-video2.component.html',
    styleUrls: ['novo-video2.component.css']
})
export class NovoVideo2Component implements OnInit, OnDestroy {

    public contrato: Contrato = new Contrato();

    public vendores: Array<Vendedor> = [];
    public masters: Array<ContratoMaster> = [];
    private sub:any;

    public cliente: Cliente = new Cliente();

    constructor(
        private route: ActivatedRoute,
        private scont: ContratoService,
        private sgprs: GprsService,
        private scli: ClienteService,
        private sfunc: FuncionarioService,
        private event: Events,
        private router: Router) {

    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {

            if ( params['id_contrato'] && params['id_contrato'] != '0' ) {

                LoadingComponent.show();
                this.scont.byPK(params['id_contrato'])
                .then((c:Contrato)=> {
                    if (! c.endereco_instalacao){
                        c.endereco_instalacao = new Street();
                    }
                    c.setores = c.setores || [];
                    this.event.publish("CHECK_UPDATE", true);
                    this.contrato = c;
                    this.loadCliente(c.id_cliente);
                })
                .catch(() => {
                    Toast.error("Problemas para buscar o Contrato!");
                });

            }

            if ( params['id_cliente'] && params['id_cliente'] != '0' ) {
                this.loadCliente(params['id_cliente']);
            }

        });

        this.contrato.tipo_contrato = 'VIDEO';

        this.loadVendedores()
            .catch(err => console.error(err));
        this.loadMasters()
            .catch(err => console.error(err));

    }

    loadCliente (id_cliente) {
        LoadingComponent.show();
        this.scli.byPK(id_cliente)
        .then((cli: Cliente) => {
            this.cliente = cli;
            this.contrato.cliente = new ClienteBasico();
            this.contrato.cliente.id_cliente = cli.id_cliente;
            this.contrato.cliente.nome = cli.nome;
            this.contrato.id_cliente = cli.id_cliente;
            this.contrato.tipo_contrato = 'VIDEO';
            LoadingComponent.hide();
        })
        .catch(() => {
            Toast.error("Problemas para buscar o Cliente!");
        });
    }



    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public changeVendedor () {
        this.vendores.forEach((f) => {
            if (Number(f.id_funcionario) === Number(this.contrato.id_vendedor)) {
                this.contrato.vendedor = f;
            }
        });
    }

    getStreet(street: Street) {
        this.contrato.endereco_instalacao = street;
    }

    async loadMasters () {
        this.masters = await this.scont.masters();
    }

    async loadVendedores () {
        this.vendores = await this.sfunc.vendedores();
    }

    save () {

        if (this.contrato.id_contratos_servicos_mastar){
            this.contrato.contrato_pago = false;
        }

        if (this.contrato.produtos_proprios == 'true'){
            this.contrato.produtos_proprios = true;
        } else if (this.contrato.produtos_proprios == 'false'){
            this.contrato.produtos_proprios = false;
        }

        this.scont.save(this.contrato)
            .then((ret: any) => {
                if (this.contrato.id_contratos_servicos) {
                    return Toast.success("Contrato Salvo com Sucesso!");
                } else {
                    this.router.navigate(['/video-monitoramento', ret.id_contratos_servicos])
                }
            })
            .catch(() => {
                Toast.error("PROBLEMAS para salvar o Contrato!");
            });
    }

    checkEmail () {
        if (this.contrato.boleto_por_email === null) {
            return false;
        }

        if (this.contrato.boleto_por_email) {
            if (this.contrato.emails.length === 0) {
                return false;
            }
        }

        return true;
    }

    checkMaster() {
        console.log(this.contrato.id_contratos_servicos_mastar);
        this.contrato.contrato_pago = !this.contrato.id_contratos_servicos_mastar;
    }

    checkSave () {

        if (! this.contrato.id_vendedor){
            return  false;
        }


        if (this.contrato.servicos_adicionais.length == 0){
            return  false;
        }

        if (!this.cliente.street.cidade){
            return false;
        }

        if (!this.contrato.produtos_proprios){
            if (this.contrato.setores.length <= 1){
                return  false;
            }
        }

        if (
            ! this.contrato.endereco_instalacao.cidade ||
            ! this.contrato.endereco_instalacao.endereco ||
            ! this.contrato.endereco_instalacao.bairro ||
            ! this.contrato.endereco_instalacao.numero
        ){
            return  false;
        }

        if (! this.contrato.carencia_mensal ){
            return  false;
        }

        if (! this.contrato.id_grupo_contrato ){
            return  false;
        }

        return true;
        //return this.checkEmail();

    }

}
