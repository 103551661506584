import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class ParceiroService {

    constructor(private http:Http) {

    }

    parceiros() {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/parceiros", header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

}
