import {Cor} from './cor';
export class DadosVeiculo {
    public ano: string;
    public cor: Cor = new Cor();
    public id_cor: number;
    public marca: string;
    public modelo: string;
    public chassi: string;
    public cidade: string;
    public estado: string;
    public renavam: string;
}
