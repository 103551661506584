import {Component, OnInit} from '@angular/core';

declare let jQuery: any;

@Component({
    selector: 'app-principal',
    templateUrl: 'principal.component.html',
    styleUrls: ['principal.component.css']
})
export class PrincipalComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }

}
