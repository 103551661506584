import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'atendimentos',
    templateUrl: 'atendimentos.component.html',
    styleUrls: ['atendimentos.component.css']
})
export class AtendimentosComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
