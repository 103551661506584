import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {IpsService} from '../../../services/index';
import {Toast} from '../../toast';
import {Contrato, IpsCadastrados, ServicoAdicional, Me} from '../../../schemas/index';
import {Auth} from "../../../utils/auth";
import {Events} from '../../../directives/index';

@Component({
    selector: 'dados-tecnicos-internet',
    templateUrl: './dados-tecnicos-internet.component.html',
    styleUrls: ['./dados-tecnicos-internet.component.css']
})
export class DadosTecnicosInternetComponent implements OnInit, OnDestroy {

    public ips: Array<IpsCadastrados> = [];

    @Input() contrato : Contrato = new Contrato();
    @Input() readonly: boolean = false;

    constructor(
        private event: Events,
        private sips: IpsService) {
    }

    servicoAdd = (service: ServicoAdicional) => {
        this.contrato.dados_internet.limit_dedicado = service[0].limit_dedicado;
        this.contrato.dados_internet.internet_dedicada = (service[0].internet_dedicado) ? 1 : 0;
        this.contrato.dados_internet.upload = service[0].upload;
        this.contrato.dados_internet.download = service[0].download;
    };

    public isAdmin(){
        let me : Me = Auth.me();
        //console.log(me);
        return me.admin;
    }

    loadIps = (id) => {
        this.sips.combo(id[0])
            .then((ven: Array<IpsCadastrados>) => this.ips = ven)
            .catch(() => Toast.error("Problemas para buscar os IPS!"));
    };

    ngOnInit() {
        this.event.subscribe('UPDATE::SERVICE', this.servicoAdd);
        this.event.subscribe('GET_IP',this.loadIps);
    }

    ngOnDestroy() {
        this.event.unsubscribe('UPDATE::SERVICE', this.servicoAdd);
        this.event.unsubscribe('GET_IP',this.loadIps);
    }

}
