import {Street} from './street';
import {Telefone} from './telefone';

export class Cliente {
    public id_cliente: number;
    public nome: string;
    public tipo_cliente: string;
    public cpf_cnpj: string;
    public rg_ie: string;
    public nascimento: string;
    public nascimento_br: string;
    public razao_social: string;
    public obs: string;
    public street: Street = new Street();
    public bloqueado = false;
    public emails: Array<string> = [];
    public telefones: Array<Telefone> = [];
}
