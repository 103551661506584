import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {ClienteService} from '../../services/index';
import {Cliente} from '../../schemas/index';

@Component({
    selector: 'search-clientes',
    templateUrl: 'search-clientes.component.html',
    styleUrls: ['search-clientes.component.css']
})
export class SearchClientesComponent implements OnInit {

    @Input() habilitar_nome: boolean = true;
    @Input() habilitar_cpfcnpj: boolean = true;
    @Input() visible: boolean = true;

    @Output() cliente = new EventEmitter();

    public myCliente = new Cliente();
    public clientes : Array<Cliente> = [];

    public search = {
        cpfcnpj: null,
        nome: null
    };

    constructor(public scli: ClienteService) {

    }

    ngOnInit() {

    }

    byNome(){
        this.scli.searchByNome(this.search.nome)
            .then((dado: Array<Cliente>) => {
                this.clientes = dado;
                this.myCliente = new Cliente();;
            })
            .catch(() => {

            });
    }

    byCpfCnpj(){
        this.scli.searchByCpfCnpj(this.search.cpfcnpj)
            .then((dado: Cliente) => {
                this.myCliente = dado;
                this.clientes = [];
            })
            .catch(() => {

            });
    }

    toCliente(){
        this.cliente.emit(this.myCliente);
    }

    send(cli){
        this.myCliente = JSON.parse(cli);
        this.toCliente();
    }

    stringify(cli){
        return JSON.stringify(cli);
    }

}
