import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class ProblemasService {

    constructor(private http:Http) {

    }

    /**
     *
     * @returns {Promise<Array<any>>}
     */
    byContrato(id_contrato) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/problemas/by-contrato/" + id_contrato, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

}
