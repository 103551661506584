import {Component, OnInit} from '@angular/core';
import {DhtmlXGrid} from '../../components/dhtmlxgrid';
import {Toast} from '../../components/toast';
import {LoadingComponent} from '../../components/loading';
import {AppSettings} from '../../app.settings';
import {Router} from '@angular/router';

declare var jQuery: any;

@Component({

    selector: 'app-clientes',
    templateUrl: 'clientes.component.html',
    styleUrls: ['clientes.component.css']
})
export class ClientesComponent implements OnInit {

    constructor(public router: Router) {

    }

    public mygrid: DhtmlXGrid;

    ngOnInit() {

        this.mygrid = new DhtmlXGrid('gridbox');
        this.mygrid.setImagePath('/dhtmlx/skins/web/imgs/');
        this.mygrid.setHeader('Nome,Email,CPF/CNPJ,Razão Social,Cidade,Bloq?');
        this.mygrid.setInitWidths('300,300,160,250,205,80');
        this.mygrid.setColAlign('left,center,center,left,left,center');
        this.mygrid.setColTypes('ro,ro,ro,ro,ro,ro');
        this.mygrid.setColSorting('str,int,str,str,str,str');
        this.mygrid.attachEvent('onFilterEnd', () => {
            this.countRow();
        });
        this.mygrid.attachHeader('#text_filter,#numeric_filter,#text_filter,#text_filter,#select_filter,#select_filter');
        this.mygrid.setSkin('dhx_web');
        this.mygrid.attachFooter('<div id=\'total\'>Total de Clientes: 0</div>,#cspan,#cspan,#cspan,#cspan,#cspan',
            ['text-align:left;padding:4px', 'text-align:right;', 'text-align:right;', 'text-align:right;', 'text-align:right;color:red;']);

        this.mygrid.attachEvent('onRowDblClicked', () => {
            this.editCliente();
        });

        this.mygrid.init();

        const res = () => {
            jQuery('#gridbox').height(jQuery(window).height() - 230);
        };
        res();
        window.onresize = res;

        this.loadGrid();

    }

    editCliente() {
        if (this.mygrid.getSelectedId()) {
            return this.router.navigate(['/cliente', this.mygrid.getSelectedId()]);
        }
        Toast.error('Selecione um Cliente!');
    }

    countRow() {
        jQuery('#total').html('Total de Clientes: <b>' + this.mygrid.getRowsNum() + '</b>');
    }

    loadGrid() {
        LoadingComponent.show();
        this.mygrid.clearAll();
        this.mygrid.load(AppSettings.API_ENDPOINT + '/grid/clientes', () => {
            this.countRow();
            this.mygrid.filterByAll();
            LoadingComponent.hide();
        }, 'json');
    }

}
