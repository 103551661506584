import {Component, OnInit} from '@angular/core';
import {Toast} from '../../../components/toast';
import {LoadingComponent} from '../../../components/loading';
import {DhtmlXGrid} from '../../../components/dhtmlxgrid';
import {OrcamentoService} from '../../../services';
import {Router} from '@angular/router';

declare var jQuery: any;

@Component({
    selector: 'app-orcamentos',
    templateUrl: 'orcamentos.component.html',
    styleUrls: ['orcamentos.component.css']
})
export class OrcamentosComponent implements OnInit {

    constructor(private router: Router, private sorc: OrcamentoService) {

    }

    public mygrid: DhtmlXGrid;

    ngOnInit() {

        this.mygrid = new DhtmlXGrid('gridbox');
        this.mygrid.setImagePath('/dhtmlx/skins/web/imgs/');
        this.mygrid.setHeader('Orçamento,Cliente,DT. Orçamento,Valor,Status');
        this.mygrid.setInitWidths('100,*,100,110,140');
        this.mygrid.setColAlign('center,left,center,center,center');
        this.mygrid.setColTypes('ro,ro,ro,ro,ro');
        this.mygrid.attachHeader('#numeric_filter,#text_filter,#text_filter,#text_filter,#select_filter');
        this.mygrid.attachFooter('<div id=\'total\'>Total de Orçamento Aguardando Aprovação: 0</div>,#cspan,#cspan,#cspan,#cspan', ['text-align:left;padding:4px', 'text-align:left;']);
        this.mygrid.attachEvent('onFilterEnd', () => {
            this.countRow();
        });
        this.mygrid.setColSorting('int,str,str,str,str');
        this.mygrid.setSkin('dhx_web');


        this.mygrid.attachEvent('onRowDblClicked', () => {
            this.edit();
        });

        this.mygrid.init();

        const res = () => {
            jQuery('#gridbox').height(jQuery(window).height() - 230);
        };
        res();
        window.onresize = res;

        this.loadGrid();

    }

    edit() {
        if (this.mygrid.getSelectedId()) {
            return this.router.navigate(['/orcamento', this.mygrid.getSelectedId()]);
        }
        Toast.error('Selecione um Orçamento!');
    }

    print() {
        if (this.mygrid.getSelectedId()) {
            return this.router.navigate(['/print-orcamento', this.mygrid.getSelectedId()]);
        }
        Toast.error('Selecione um Orçamento!');
    }

    novo() {
        this.router.navigate(['/orcamento']);
    }

    financeiro() {
        if (this.mygrid.getSelectedId()) {
            return this.router.navigate(['/orcamento2financeiro', this.mygrid.getSelectedId()]);
        }
        Toast.error('Selecione um Orçamento!');
    }

    excluir() {

        if (!this.mygrid.getSelectedId()) {
            return Toast.error('Selecione um Orçamento!');
        }

        if (confirm('Confirma a Exclusão do Orçamento?')) {

            this.sorc.excluir(this.mygrid.getSelectedId())
                .then(() => {
                    Toast.success('Orçamento excluído!');
                    this.loadGrid();
                })
                .catch(() => {
                    Toast.error('Problemas para excluir o Orçamento!');
                });

        }

    }

    countRow() {
        jQuery('#total').html('Total de Orçamento Aguardando Aprovação: <b>' + this.mygrid.getRowsNum() + '</b>');
    }

    loadGrid() {

        LoadingComponent.show();
        this.mygrid.clearAll();

        this
            .sorc
            .gridOrcamentoAguardando()
            .then((data) => {
                this.mygrid.parse(data, () => {
                    this.countRow();
                    this.mygrid.filterByAll();
                    LoadingComponent.hide();
                }, 'json');
            })
            .catch(() => {
                LoadingComponent.hide();
            });

    }

}
