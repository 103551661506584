export class VendaFinanceiro {
    public id_venda: number;
    public data_venda: string;
    public valor_total: number;
    public entrada: number;
    public parcelas: number;
    public valor_parcela: number;
    public mao_de_obra: number;
    public valor_produtos: number;
    public desconto: number;
    public forma_pagamento: string;
}
