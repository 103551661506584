import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {AppSettings} from '../app.settings';
import {MyHeaders} from '../utils/my-headers';

@Injectable()
export class AuthService {

    isAuthenticated:boolean = false;

    constructor(private http:Http) {

    }

    authenticate(usercreds) {

        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.post(AppSettings.API_ENDPOINT + "/auth", usercreds, header.json())
            .subscribe(
                (data) => {

                    let dados = data.json();
                    window.localStorage.setItem('auth_key', dados.token);
                    window.localStorage.setItem('id_funcionario', dados.id_funcionario);

                    this.http.post(AppSettings.API_ENDPOINT + "/me", {token: dados.token}, header.json())
                        .subscribe(
                            (datame) => {
                                let dados = datame.json();
                                window.localStorage.setItem('me', JSON.stringify(dados));
                                //console.log(dados);
                                this.isAuthenticated = true;
                                resolve(this.isAuthenticated);
                            },
                            () => {
                                fail();
                            }
                        );

                },
                () => {
                    fail();
                }
            );
        });

    }

    logout() {
        return new Promise((resolve) => {
            window.localStorage.clear();
            resolve();
        });
    }

}
