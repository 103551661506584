import {Component, OnInit, OnDestroy} from '@angular/core';
import {Contrato, Cliente} from '../../schemas';
import {ContratoService, ClienteService} from '../../services';
import {Router, ActivatedRoute} from '@angular/router';
import {LoadingComponent} from '../../components/loading';
import {Toast} from '../../components/toast';

@Component({
    selector: 'app-transferir2',
    templateUrl: './transferir2.component.html',
    styleUrls: ['./transferir2.component.css']
})
export class Transferir2Component implements OnInit, OnDestroy {

    public contrato: Contrato;
    public cliente: Cliente = new Cliente();
    public contrato_old = null;

    private sub: any;

    constructor(
        private route: ActivatedRoute,
        private scont: ContratoService,
        private scli: ClienteService,
        private router: Router) {

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            if (params['id_cliente'] && params['id_cliente'] != '0') {
                this.loadCliente(params['id_cliente']);
            }
        });
    }

    consultaContrato() {
        LoadingComponent.show();
        this.scont.byContrato(this.contrato_old)
            .then((cli: Contrato) => {
                this.contrato = cli;
                LoadingComponent.hide();
            })
            .catch(() => {
                Toast.error('Contrato não encontrado!');
                LoadingComponent.hide();
            });
    }

    loadCliente(id_cliente) {
        LoadingComponent.show();
        this.scli.byPK(id_cliente)
            .then((cli: Cliente) => {
                this.cliente = cli;
                LoadingComponent.hide();
            })
            .catch(() => {
                Toast.error('Problemas para buscar o Cliente!');
            });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    save() {
        let param: any = {
            id_contratos_servicos: this.contrato.id_contratos_servicos,
            id_cliente: this.cliente.id_cliente
        };
        this.scont.transferir(param)
            .then(() => {
                Toast.success('Contrato Salvo com Sucesso!');
                this.router.navigate(['/start']);
            })
            .catch(() => {
                Toast.error('PROBLEMAS para salvar o Contrato!');
            });
    }

    checkSave() {
        if (!this.contrato) {
            return false;
        }
    }

}
