import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';
import {Boleto} from '../schemas/index';

@Injectable()
export class BoletoService {

    constructor(private http:Http) {

    }

    byPK(id_boleto) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/boleto/by-pk/" + id_boleto, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

    baixar(json: Boleto) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.put(AppSettings.API_ENDPOINT + "/boletos/baixar/" + json.id_boletos, json, header.json())
            .subscribe(
                () => {
                    resolve();
                },
                () => {
                    fail();
                }
            );
        });
    }

}
