import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';

@Injectable()
export class TipoSolicitacaoService {

    constructor(private http:Http) {

    }

    /**
     *
     * @returns {Promise<Array<any>>}
     */
    byInstalacao(instalacao) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/ordem_servico/tipo_solicitacao/" + instalacao, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

}
