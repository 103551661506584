import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../../components/toast';
import {Router, ActivatedRoute} from '@angular/router';
import {Orcamento, Street, Produto, Contrato, Cliente} from '../../../schemas';
import {OrcamentoService, ProdutosService, ContratoService, ClienteService} from '../../../services';

@Component({
    selector: 'app-aprovar-orcamento',
    templateUrl: 'aprovar-orcamento.component.html',
    styleUrls: ['aprovar-orcamento.component.css']
})
export class AprovarOrcamentoComponent implements OnInit, OnDestroy {

    public orcamento: Orcamento = new Orcamento();
    public contrato: Contrato;
    public cliente: Cliente;

    public simnao: Array<any> = [
        {nome: 'SIM', value: 1},
        {nome: 'NÃO', value: 0}
    ];

    public tiponota: Array<any> = [
        {nome: 'VENDA', value: 'VENDA'},
        {nome: 'COMODATO', value: 'COMODATO'},
        {nome: 'ISENTO', value: 'ISENTO'},
        {nome: 'DOACAO', value: 'DOACAO'}
    ];

    public produtosQtdeError = false;
    public aprovando = false;
    private sub: any;

    totalProduto() {
        let total = 0;
        this.orcamento.produtos.forEach((p) => {
            total = total + (p.qtde * p.valor);
        });
        return total;
    }

    constructor(
        private sorc: OrcamentoService,
        private sprod: ProdutosService,
        private scont: ContratoService,
        private scli: ClienteService,
        private router: Router,
        private route: ActivatedRoute) {

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    checkAvancar() {

        if (this.aprovando) {
            // alert('nao aprovado');
            return false;
        }

        // if (this.produtosQtdeError) {
        //    return false;
        // }

        // if (!this.orcamento.criar_os && this.orcamento.criar_os !== 0) {
            // alert('nao aprovado 1');
        //    return false;
        // }

        if (!this.orcamento.entrada_recebida && this.orcamento.entrada_recebida !== 0) {
            // alert('nao aprovado 2');
            return false;
        }

        if (!this.orcamento.tipo_nota) {
            // alert('nao aprovado 3');
            return false;
        }

        return true;

    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {

            if (params['id_orcamento'] && params['id_orcamento'] != '0') {
                this.sorc.byPK(params['id_orcamento'])
                    .then((c: Orcamento) => {

                        if (!c.endereco_instalacao) {
                            c.endereco_instalacao = new Street();
                        }

                        if (!c.produtos_retirar) {
                            c.produtos_retirar = [];
                        }

                        if (c.id_contratos_servicos) {
                            this.loadContrato(c.id_contratos_servicos);
                        }

                        this.orcamento = c;

                        this.orcamento.criar_os = 1;

                        this.orcamento.tipo_nota = this.orcamento.tipo_venda;

                        this.loadQtdeByOrcamento();

                    });
            }

        });
    }

    loadContrato (id) {
        this
            .scont
            .byPK(id)
            .then((cont: Contrato) => {
                this.contrato = cont;
                if (cont.id_cliente) {
                    this.loadCliente(cont.id_cliente);
                }
            })
            .catch((err) => console.error(err));
    }

    loadCliente (id) {
        this
            .scli
            .byPK(id)
            .then((cont: Cliente) => {
                this.cliente = cont;
            })
            .catch((err) => console.error(err));
    }

    loadQtdeByOrcamento() {

        // setTimeout(() => {

            const process = (data: Array<Produto>) => {

                this.produtosQtdeError = false;

                data.forEach((pa) => {
                    this.orcamento.produtos.forEach((p) => {
                        if (pa.id_produto === p.id_produto) {
                            p.qtde_estoque = pa.qtde;
                        }
                    });
                });

                this.orcamento.produtos.forEach((p: Produto) => {
                    if (p.qtde_estoque < p.qtde) {
                        this.produtosQtdeError = true;
                    }
                });

            };

            if (this.orcamento.tipo_nota === 'VENDA') {

                this.sprod.qtdeByOrcamento(this.orcamento.id_orcamento)
                    .then(process)
                    .catch(() => {
                        Toast.error('Problemas para buscar os dados do Estoque!');
                    });

            } else {

                this.sprod.qtdeByOrcamentoComodato(this.orcamento.id_orcamento)
                    .then(process)
                    .catch(() => {
                        Toast.error('Problemas para buscar os dados do Estoque!');
                    });

            }

        // }, 300);

    }

    sumTaxa() {
        // setTimeout(() => {
            Orcamento.sumTaxaInstalacao(this.orcamento);
        // }, 300);
    }

    aprovar() {

        if (this.aprovando) {
            return;
        }

        if (!this.orcamento.criar_os) {
            return Toast.error('Informe se é para criar Ordem de Serviço.');
        } else if (!this.orcamento.entrada_recebida) {
            return Toast.error('Informe se é ja recebeu a entrada.');
        }

        this.aprovando = true;
        this.orcamento.criar_os = Number(this.orcamento.criar_os);
        this.orcamento.entrada_recebida = Number(this.orcamento.entrada_recebida);

        this.sorc
            .aprovar(this.orcamento)
            .then((json: any) => {
                this.aprovando = false;
                Toast.success('Orçamento Aprovado!');
                this.router.navigate(['/status-venda/', json.id_venda]);
            })
            .catch(() => {
                this.aprovando = false;
                Toast.error('Problemas para aprovar o Orçamento!');
            });
    }

}
