import {Component, OnInit, OnDestroy} from '@angular/core';
import {Toast} from '../../components/toast';
import {Orcamento, EmpresaFiscal} from '../../schemas';
import {OrcamentoService, EmpresaFiscalService} from '../../services';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-print-orcamento',
    templateUrl: 'print-orcamento.component.html',
    styleUrls: ['print-orcamento.component.css']
})
export class PrintOrcamentoComponent implements OnInit, OnDestroy {

    public orcamento: Orcamento = new Orcamento();
    public empresa: EmpresaFiscal = new EmpresaFiscal();

    constructor(
        private route: ActivatedRoute,
        private sorc: OrcamentoService,
        private sfisc: EmpresaFiscalService) {

    }

    private sub: any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {

            if (params['id_orcamento'] && params['id_orcamento'] != '0') {
                this.loadOrcamento(params['id_orcamento']);
            }

        });
    }

    private loadOrcamento(id_orcamento) {
        this.sorc.byPK(id_orcamento)
        .then((c: Orcamento) => {
            this.orcamento = c;

            this.sfisc.byPK(c.id_empresa_fiscal).then((row: EmpresaFiscal) => {
                this.empresa = row;
            });
        })
        .catch(() => {
            Toast.error('Problemas para buscar o Orçamento');
        });
    }

}
