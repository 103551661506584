import {Component} from '@angular/core';

@Component({

    selector: 'voltar',
    template: '<button (click)="voltar()" class="waves-effect grey waves-light btn"><i class="material-icons left">skip_previous</i>VOLTAR</button>'
})
export class Voltar{

    constructor(){

    }

    voltar(){
        Voltar.goback();
    }

    static goback(){
        window.history.back();
    }

}
