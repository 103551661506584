import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {

    public localUser: any = {};

    constructor(private auth: AuthService, private router: Router) {
    }

    ngOnInit() {
        this.localUser = {
            username: '',
            password: ''
        };
    }

    login() {
        this.auth.authenticate(this.localUser)
        .then((res) => {
            if (res) {
                this.router.navigate(['/start']);
            } else {
                console.log('Invalid user');
                this.ngOnInit();
            }
        });
    }

}
