import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';
import {Cliente} from '../schemas/index';

@Injectable()
export class ClienteService {

    constructor(private http:Http) {

    }

    checarCpfCnpj(cpfcnpj) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.post(AppSettings.API_ENDPOINT + "/cpf-cnpj/checar", {cpfcnpj: cpfcnpj}, header.json())
            .subscribe(
                () => {
                    resolve();
                },
                () => {
                    fail();
                }
            );
        });
    }

    getEndereco(id_cliente) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/clientes/get-endereco/" + id_cliente, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                () => {
                    fail();
                }
            );
        });
    }

    byPK(id_cliente) {
        return new Promise((resolve) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/clientes/by-pk/" + id_cliente, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                }
            );
        });
    }

    /**
     *
     * @param json
     * @return {Promise<any>}
     */
    save(json: Cliente) {
        if(json.id_cliente){
            return this.update(json);
        } else {
            return this.insert(json);
        }
    }

    private insert(json: Cliente){
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.post(AppSettings.API_ENDPOINT + "/clientes/by-pk/0", json, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                () => {
                    fail();
                }
            );
        });
    }

    private update(json: Cliente){
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.put(AppSettings.API_ENDPOINT + "/clientes/by-pk/" + json.id_cliente, json, header.json())
            .subscribe(
                () => {
                    resolve({id_cliente: json.id_cliente});
                },
                () => {
                    fail();
                }
            );
        });
    }

    searchByCpfCnpj(cpfcnpj:string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.post(AppSettings.API_ENDPOINT + "/clientes/by-cpfcnpj/0",{'cpfcnpj': cpfcnpj}, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                ()=> {
                    fail();
                }
            );
        });
    }

    searchByRazaoSocial(nome:string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/clientes/by-razaosocial/" + nome, header.json())
                .subscribe(
                    (data) => {
                        resolve(data.json());
                    },
                    ()=> {
                        fail();
                    });
        });
    }

    searchByNome(nome:string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/clientes/by-nome/" + nome, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                ()=> {
                    fail();
                });
        });
    }

    searchByCodigo(codcli:string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/clientes/by-codigo/" + codcli, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                ()=> {
                    fail();
                });
        });
    }

    searchByPlaca(placa:string) {
        return new Promise((resolve, fail) => {
            let header = new MyHeaders();
            this.http.get(AppSettings.API_ENDPOINT + "/clientes/by-placa/" + placa, header.json())
            .subscribe(
                (data) => {
                    resolve(data.json());
                },
                ()=> {
                    fail();
                });
        });
    }

}
