import {Component, OnInit, Input} from '@angular/core';
import {Contrato, Cor} from '../../../schemas/index';
import {CorService} from '../../../services/index';
import {Toast} from '../../../components/toast';

@Component({
    selector: 'dados-veiculo',
    templateUrl: 'dados-veiculo.component.html',
    styleUrls: ['dados-veiculo.component.css']
})
export class DadosVeiculoComponent implements OnInit {

    @Input() contrato: Contrato = new Contrato();

    public cores: Array<Cor> = [];

    public estados: Array<string> = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

    constructor(private scor: CorService) {

    }

    ngOnInit() {
        this.loadCor();
    }

    changeCor() {
        setTimeout(() => {
            this.cores.forEach((c) => {
                if (c.id_cor == this.contrato.dados_veiculo.id_cor) {
                    this.contrato.dados_veiculo.cor = c;
                }
            });
        }, 300);
    }

    stringify(cor) {
        return JSON.stringify(cor);
    }

    loadCor() {

        this.scor.cores()
            .then((cor: Array<Cor>) => {
                this.cores = cor;
            })
            .catch(() => {
                Toast.error('Cores não carregadas!');
            });

    }

}
