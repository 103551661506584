import {Component, OnInit} from '@angular/core';
import {LoadingComponent} from '../../../components/loading';
import {DhtmlXGrid} from '../../../components/dhtmlxgrid';
import {Toast} from '../../../components/toast';
import {Router} from '@angular/router';
import {ContratoService} from '../../../services/index';

declare var jQuery: any;

@Component({
    selector: 'app-video-monitoramento',
    templateUrl: 'video-monitoramento.component.html',
    styleUrls: ['video-monitoramento.component.css']
})
export class VideoMonitoramentoComponent implements OnInit {

    constructor(private router: Router, private scont: ContratoService) {

    }

    novo() {
        this.router.navigate(['/novo-video']);
    }

    public mygrid: DhtmlXGrid;

    ngOnInit() {

        this.mygrid = new DhtmlXGrid('gridbox');

        this.mygrid.setHeader("Nome,Mensalidade,Vendedor,Contrato,Parceiro,Status");
        this.mygrid.setInitWidths("400,120,150,105,180,150");
        this.mygrid.setColAlign("left,center,left,left,center,center");
        this.mygrid.setColTypes("ro,ro,ro,ro,ro,ro");
        this.mygrid.setColSorting("str,int,str,str,str,str");
        this.mygrid.attachEvent("onFilterEnd",() => {
            this.countRow();
        });
        this.mygrid.attachHeader("#text_filter,#numeric_filter,#select_filter,#text_filter,#select_filter,#select_filter");
        this.mygrid.setSkin("dhx_web");
        this.mygrid.attachFooter("<div id='total'>Total de Vídeo Monitoramento: 0</div>,#cspan,#cspan,#cspan,#cspan,#cspan",
            ["text-align:left;padding:4px","text-align:right;","text-align:right;","text-align:right;","text-align:right;color:red;"]);

        this.mygrid.attachEvent("onRowDblClicked", () => {
            this.editRastreamento();
        });

        this.mygrid.init();

        let res = () => {
            jQuery('#gridbox').height(jQuery(window).height() - 230);
        };
        res();
        window.onresize = res;

        this.loadGrid();

    }

    editRastreamento() {
        if (this.mygrid.getSelectedId()) {
            return this.router.navigate(['/video-monitoramento', this.mygrid.getSelectedId()]);
        }
        Toast.error("Selecione um Vídeo Monitoramento!");
    }

    excluir(){

        if (!this.mygrid.getSelectedId()) {
            return Toast.error("Selecione um Vídeo Monitoramento!");
        }

        if (confirm("Confirma a Exclusão do Vídeo Monitoramento?")) {

            this.scont.excluir(this.mygrid.getSelectedId())
            .then(() => {
                Toast.success("Monitoramento excluído!");
                this.loadGrid();
            })
            .catch(() => {
                Toast.error("Problemas para excluir o Vídeo Monitoramento!");
            });

        }

    }

    countRow() {
        jQuery('#total').html("Total de Vídeo Monitoramento: <b>" + this.mygrid.getRowsNum() + "</b>");
    }

    loadGrid() {

        LoadingComponent.show();

        this.scont.gridVideoMonitoramento()
        .then((data) => {
            this.mygrid.clearAll();
            this.mygrid.parse(data, () => {
                this.countRow();
                this.mygrid.filterByAll();
                LoadingComponent.hide();
            }, 'json');
        })
        .catch(() => {
            LoadingComponent.hide();
        });

    }

    print(){
        if (this.mygrid.getSelectedId()) {
            window.open('http://ca.centeralarm.com.br/financeiro/print/print_contrato.php?id_contratos_servicos=' + this.mygrid.getSelectedId(),'_blank');
        }
        Toast.error("Selecione uma Internet!");
    }

}
