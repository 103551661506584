import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {MyHeaders} from '../utils/my-headers';
import {AppSettings} from '../app.settings';
import {Vendedor} from '../schemas';

@Injectable()
export class FuncionarioService {

    constructor(private http: Http) {

    }

    /**
     *
     * @returns {Promise<Array<any>>}
     */
    tecnicos() {
        return new Promise((resolve, fail) => {
            this.http.get(AppSettings.API_ENDPOINT + '/tecnicos/ativos', new MyHeaders().json())
                .subscribe(
                    (data) => resolve(data.json()),
                    (err) => fail(err));
        });
    }

    /**
     *
     * @returns {Promise<Array<Vendedor>>}
     */
    vendedores(): Promise<Array<Vendedor>> {
        return new Promise((resolve, fail) => {
            this.http.get(AppSettings.API_ENDPOINT + '/vendedores/ativos', new MyHeaders().json())
                .subscribe(
                    (data) => resolve(data.json()),
                    (err) => fail(err));
        });
    }

}
