import {Component, OnInit} from '@angular/core';

declare var jQuery: any;

@Component({
    selector: 'loading',
    templateUrl: 'loading.component.html',
    styleUrls: ['loading.component.css']
})
export class LoadingComponent implements OnInit {

    constructor() {

    }

    static show(){
        jQuery('.loading').css( 'display', 'block');
    }

    static hide(){
        jQuery('.loading').css( 'display', 'none');
    }

    ngOnInit() {
        jQuery('.loading')
            .css( 'right', jQuery(window).width() / 2 )
            .css( 'top', jQuery(window).height() / 2 );
    }

}
